import axios from "axios"
import { DateRange } from "../components/meetingModal/datePicker/hourPicker/hourPicker"
import {
  ContactFormValues,
  consents,
} from "../components/sections/static-data/formularz"
import { format } from "date-fns"

axios.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    return Promise.reject(error.response)
  }
)

export interface FormParams {
  captcha: string
  formValue: ContactFormValues
  dates?: DateRange
  useContactForm?: boolean
}

export const submitData = async ({ captcha, formValue, dates, useContactForm }: FormParams) => {
  const consentsArray = consents.map(consent => {
    const accepted = !!formValue[consent.name]
    delete formValue[consent.name]

    return {
      key: consent.name,
      name: consent.placeholder,
      timestamp: new Date(),
      accepted,
    }
  })

  const pathParts = window.location.pathname.split("/")
  const pathPartner = pathParts[pathParts.length - 1]
  const partner = "Grupa VW" + (pathPartner ? `/${pathPartner}` : "")

  const dateFormat = "yyyy-MM-dd HH:mm"

  const mappedValues = {
    ...formValue,
    consents: JSON.stringify(useContactForm ? [consentsArray[0]] : consentsArray),
    ...(dates
      ? {
        contactDateFrom: format(dates.start, dateFormat),
        contactDateTo: format(dates.end, dateFormat),
      }
      : {}),
    recaptchaToken: captcha,
    partner,
    clientType: formValue.commercialClient
      ? ClientType.Commercial
      : ClientType.Individual,
  }

  const formData = new FormData()
  Object.keys(mappedValues).forEach(key => {
    formData.append(key, mappedValues[key])
  })

  let apiCall;

  if (useContactForm == true) {
    apiCall = await axios.post<string>(
      process.env.GATSBY_API_URL + "ContactForm",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
  } else {
    apiCall = await axios.post<string>(
      process.env.GATSBY_API_URL + "WorkflowTickets",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
  }
  return (
    apiCall
  ).data
}

export enum ClientType {
  Individual = "Individual",
  Commercial = "Commercial",
}
