import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { BaseProps } from "../../utils/baseProps"
import { makeStyles } from "@material-ui/styles"
import { Theme, createStyles, darken } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: "#FF6C36",
      opacity: 0,
      zIndex: 1,
      borderRadius: "8px",
    },
  })
)

interface props extends BaseProps {
  relative: boolean
}

const GwarancjaCenyImage: React.FC<props> = ({ className, relative }) => {
  const styles = useStyles()

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "gwarancja-ceny.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const image = (
    <>
      <div className={styles.overlay}></div>
      <Img
        alt="gwarancja_ceny"
        className={className}
        fluid={data.placeholderImage.childImageSharp.fluid}
        imgStyle={{
          objectPosition: "top",
        }}
      />
    </>
  )

  return relative ? <div className={styles.container}>{image}</div> : image
}

export default GwarancjaCenyImage
