import React, { useState, useEffect, Dispatch, SetStateAction } from "react"
import {
  makeStyles,
  createStyles,
  Theme,
  useMediaQuery,
  useTheme,
  Grid,
} from "@material-ui/core"
import {
  format,
  addMinutes,
  isBefore,
  min,
  set,
  isAfter,
  isSameDay,
  isFuture,
  getDay,
} from "date-fns"
import { pl } from "date-fns/locale"
import {
  getWorkingHours,
  periodLength,
} from "../../../sections/static-data/formularz"
import { getCurrentDay } from "../datePicker"
import { greyColor } from "../../../../styles/constants"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      margin: "12px 0 0",
    },
    headersContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "8px",
      justifyContent: "flex-start",
      "& h3": {
        margin: 0,
        color: greyColor,
        fontSize: "17px",
        fontWeight: theme.typography.fontWeightBold,
        textTransform: "capitalize",
      },
      "& h2": {
        margin: "0 0 8px",
        fontSize: "25px",
        fontWeight: theme.typography.fontWeightLight,
      },
      [theme.breakpoints.down("sm")]: {
        "& h3": {
          fontSize: "15px",
        },
        "& h2": {
          fontSize: "22px",
        },
        textAlign: "center",
        alignSelf: "center",
        margin: "12px 0 0",
      },
    },
    hoursContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      overflow: "auto",
      maxHeight: "360px",
      [theme.breakpoints.down("sm")]: {
        height: "160px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "132px",
      },
    },
    hourButton: {
      maxWidth: "110px",
      margin: "8px",
      padding: "18px 8px",
      background: theme.palette.primary.light,
      borderRadius: "10px",
      cursor: "pointer",
      fontSize: "14px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        flexBasis: 0,
      },
      [theme.breakpoints.down("xs")]: {
        alignSelf: "center",
        fontSize: "13px",
        padding: "12px 8px",
      },
    },
    disabled: {
      cursor: "default",
      color: theme.palette.secondary.light,
    },
    selected: {
      color: "white",
      background: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  })
)

export interface DateRange {
  start: Date
  end: Date
}

interface Props {
  date?: Date
  setHoursPeriod?: (range: DateRange) => void
}

export const HourPicker: React.FC<Props> = ({ date, setHoursPeriod }) => {
  const classes = useStyles()
  const currentDay = getCurrentDay()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [currentDate, setCurrentDate] = useState(date || currentDay)
  const [selectedHour, setSelectedHour] = useState(-1)
  const [hoursArray, setHoursArray] = useState([])

  useEffect(() => {
    const hoursInt = hoursInterval(currentDate, periodLength)
    setHoursArray(hoursInt)
  }, [currentDate])

  useEffect(() => {
    if (!hoursArray || !hoursArray.length) {
      return
    }

    const currentIndex = selectedHour
    const firstProperDateIndex = hoursArray.findIndex(date =>
      isFuture(date.start)
    )

    const indexToSet = Math.max(currentIndex, firstProperDateIndex)
    setSelectedHour(indexToSet)
    setHoursPeriod(hoursArray[indexToSet])
  }, [hoursArray])

  useEffect(() => {
    if (isAfter(date, currentDay) || isSameDay(date, currentDay)) {
      setCurrentDate(date)
    } else {
      setCurrentDate(currentDay)
    }
  }, [date])

  const dateFormatter = (date: Date): string => {
    const formatted = format(date, "eeee, dd MMMM", { locale: pl })
    return formatted
  }

  const hoursInterval = (currDate: Date, periodLength: number) => {
    const hoursRange = getWorkingHours(getDay(currDate))

    if (!hoursRange) return []

    let startDate = set(currDate, {
      hours: hoursRange.startHour,
      minutes: 0,
      seconds: 0,
    })
    let endDate = set(currDate, {
      hours: hoursRange.endHour,
      minutes: 0,
      seconds: 0,
    })
    const hours = []

    while (isBefore(startDate, endDate)) {
      let end = min([endDate, addMinutes(startDate, periodLength)])
      hours.push({
        start: startDate,
        end,
      })
      startDate = end
    }
    return hours
  }

  const hoursDisplayed = hoursArray.map((period, i) => {
    const startFormat = format(period.start, "HH:mm")
    const endFormat = format(period.end, "HH:mm")
    return (
      <Grid
        item
        xs={4}
        className={`${classes.hourButton} ${
          selectedHour === i
            ? classes.selected
            : isBefore(period.start, currentDay) && classes.disabled
        } `}
        key={i}
        onClick={() => {
          if (!isBefore(period.start, currentDay)) {
            setSelectedHour(i)
            setHoursPeriod(hoursArray[i])
          }
        }}
      >{`${startFormat} - ${endFormat}`}</Grid>
    )
  })

  return (
    currentDate && (
      <div className={classes.root}>
        <div className={classes.headersContainer}>
          <h3>{dateFormatter(currentDate)}</h3>
          <h2>Wybierz godzinę:</h2>
        </div>
        <Grid
          container
          direction={smallScreen ? "column" : "row"}
          alignItems="center"
          className={classes.hoursContainer}
        >
          {hoursDisplayed}
        </Grid>
      </div>
    )
  )
}
