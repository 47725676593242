// If you don't want to use TypeScript you can delete this file!
import React from "react"
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Theme,
  createStyles,
} from "@material-ui/core"
import { BaseProps } from "../utils/baseProps"

export interface ColumnConfig {
  id: string
  format?: (value: any) => string | React.ReactNode
  label: string | React.ReactNode
  minWidth: number
}

export interface TableProps extends BaseProps {
  columnsConfig: ColumnConfig[]
  data: any[]
  priceType: 0 | 1
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      border: `3px solid ${theme.palette.grey[300]}`,
      borderRadius: "8px",
      "& .MuiTableCell-root": {
        paddingLeft: "2px",
        paddingRight: "2px",
        textAlign: "center",
        "&:nth-last-child(1)": {},
      },
    },
    headTh: {
      backgroundColor: theme.palette.grey[300],
      fontWeight: theme.typography.fontWeightBold,
    },
  })
)

const TableWrapper: React.FC<TableProps> = ({
  columnsConfig,
  data,
  className,
  priceType,
}) => {
  const styles = useStyles()

  return (
    <TableContainer className={`${styles.table} ${className}`}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columnsConfig.map(column => (
              <TableCell
                key={column.id}
                style={{ minWidth: column.minWidth }}
                className={styles.headTh}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow hover tabIndex={-1} key={row.group + index}>
                {columnsConfig.map(column => {
                  let value = row[column.id]
                  if (column.id === "ratioGrossNet") {
                    value =
                      priceType === 0 ? row["ratioNet"] : row["ratioGross"]
                  }
                  return (
                    <TableCell key={column.id}>
                      {column.format ? column.format(value) : value}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableWrapper
