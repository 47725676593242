import React, { useEffect, useState } from "react"
import TableWrapper, { ColumnConfig } from "../Table"
import { Typography, createStyles, makeStyles, Theme } from "@material-ui/core"
import theme from "../../styles/theme"
import StyledSlider from "../Slider"
import { useSpring, animated, to } from "react-spring"

export type durationType = 2 | 3 | 5
export type priceListType = "cena" | "rabat"
export type priceType = 0 | 1

export interface FeeParams {
  duration?: durationType
  priceType: priceType
  value?: number
}

export const Ratio: React.FC<FeeParams> = ({ priceType, value }) => {
  const [values, setValues] = useState<number[]>([])

  const getValue = () => {
    return value
  }

  useEffect(() => {
    const newValue = getValue()
    setValues([...values, newValue])
  }, [priceType])

  const numOfValues = values.length

  const props = useSpring({
    number: numOfValues > 0 ? values[numOfValues - 1] : 0,
    from: { number: numOfValues > 1 ? values[numOfValues - 2] : 0 },
  })
  return (
    <animated.span>
      {to(
        props.number,
        val => parseFloat(val.toString()).toFixed(4) + " zł / kWh"
      )}
    </animated.span>
  )
}

export const Fee: React.FC<FeeParams> = ({ duration, priceType }) => {
  const [values, setValues] = useState<number[]>([])

  const getValue = () => {
    if (Boolean(priceType)) {
      switch (duration) {
        case 2:
          return 12.3
        case 3:
          return 14.76
        case 5:
          return 30.75
      }
    } else {
      switch (duration) {
        case 2:
          return 10
        case 3:
          return 12
        case 5:
          return 25
      }
    }
  }

  useEffect(() => {
    const newValue = getValue()
    setValues([...values, newValue])
  }, [duration, priceType])

  const numOfValues = values.length

  const props = useSpring({
    number: numOfValues > 0 ? values[numOfValues - 1] : 0,
    from: { number: numOfValues > 1 ? values[numOfValues - 2] : 0 },
  })
  return (
    <animated.span>
      {to(
        props.number,
        val => parseFloat(val.toPrecision(val >= 10 ? 4 : 3)) + " zł / miesiąc"
      )}
    </animated.span>
  )
}

const columns: (feeParams: FeeParams) => ColumnConfig[] = (
  feeParams: FeeParams
) => [
  {
    id: "group",
    label: "Grupa taryfowa",
    minWidth: 95,
    format: value => (
      <Typography
        variant={value.length > 3 ? "caption" : "body1"}
        style={{
          fontWeight: theme.typography.fontWeightBold,
          lineHeight: "10px",
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: "rate",
    label: (
      <Typography
        style={{
          fontSize: "11px",
          lineHeight: "13px",
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        Rodzaj stawki stanowiącej składnik ceny sprzedaży energii
      </Typography>
    ),
    minWidth: 125,
  },
  {
    id: "ratioGrossNet",
    label: "Cena/Stawka opłaty",
    minWidth: 125,
    format: (value: number) => <Ratio {...feeParams} value={value} />,
  },
  {
    id: "fee",
    label: "Opłata stała",
    minWidth: 125,
    format: () => <Fee {...feeParams} />,
  },
]

const dlugoscUmowy = [
  {
    value: 2,
    label: "2 lata",
  },
  {
    value: 3,
    label: "3 lata",
  },
  {
    value: 5,
    label: "5 lat",
  },
]

const cena = [
  {
    value: 0,
    label: "Netto",
  },
  {
    value: 1,
    label: "Brutto",
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lastSlider: {
      marginBottom: "20px",
    },
    flex: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
    label: {
      whiteSpace: "pre",
      marginRight: "20px",
      minWidth: "225.25px",
    },
  })
)

const defaultDuration = 2

export const CennikCena: React.FC<{ prices: any[] }> = ({ prices }) => {
  const styles = useStyles()
  const [duration, setDuration] = useState<2 | 3 | 5>(defaultDuration)
  const [priceType, setPriceType] = useState<0 | 1>(1)

  return (
    <>
      <div className={`${styles.flex}`}>
        <Typography className={styles.label}>
          Zaznacz czas trwania umowy:
        </Typography>
        <StyledSlider
          marks={dlugoscUmowy}
          step={null}
          min={2}
          max={5}
          track={false}
          value={duration}
          onChange={(_, value) => {
            setDuration(value as 2 | 3 | 5)
          }}
        />
      </div>
      <div className={`${styles.flex} ${styles.lastSlider}`}>
        <Typography className={styles.label}>Cena:</Typography>

        <StyledSlider
          marks={cena}
          step={null}
          min={0}
          max={1}
          track={false}
          value={priceType}
          onChange={(_, value) => {
            setPriceType(value as 0 | 1)
          }}
        />
      </div>
      <TableWrapper
        columnsConfig={columns({
          duration,
          priceType,
        })}
        data={prices}
        priceType={priceType}
      />
    </>
  )
}
