// If you don't want to use TypeScript you can delete this file!
import React from "react"
import InputMask from "react-input-mask"
import { IMaskMixin } from "react-imask"

import {
  TextField,
  withTheme,
  withStyles,
  Theme,
  makeStyles,
} from "@material-ui/core"
import { Transition, animated } from "react-spring"

export const StyledTextField = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& input": {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: "13px",
      padding: "2px 0",
    },
    "& label": {
      width: "100%",
      top: "2px",
      fontSize: "13px",
      padding: "2px 0 !important",
      fontWeight: 600,
      opacity: 0.75,
      transform: "none",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#3A3A3A",
      "&.Mui-focused": {
        color: "#3A3A3A",
      },
    },
    "& div": {
      margin: 0,
    },
    "& div:after, div:before": {
      display: "none",
    },
  },
}))(withTheme(TextField))

const MaskedStyledInput = IMaskMixin(({ ...props }) => (
  <StyledTextField {...props} />
))

const useStyles = makeStyles((theme: Theme) => ({
  singleInput: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    height: "54px",
    padding: "0 4px 0 16px",
    border: "2px solid transparent",
    borderRadius: "7px",
    boxShadow:
      "inset -2px -2px 7px rgba(255, 255, 255, 0.75), inset 1px 1px 5px rgba(61, 61, 61, 0.15)",
    backgroundColor: "#fcfcfc",
    color: "#3A3A3A",
    letterSpacing: "1.16px",

    transition: "border 0.2s ease-in-out",
    overflow: "hidden",

    "& p": {
      color: "#CC1450",
      padding: 0,
      fontSize: "10px",
      boxSizing: "border-box",
      overflow: "hidden",
      margin: 0,
    },

    [theme.breakpoints.down("sm")]: {
      margin: "5px 0",
    },
  },
  error: {
    border: "2px solid #CC1450",
    justifyContent: "flex-start",
  },
  Autocomplete: {
    paddingTop: "2px !important",
  },
}))
interface Props {
  field: {
    name: string
    value: string
  }
  disabled?: boolean
  metaError: string
  showError: boolean
  placeholder:
    | string
    | React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      >
  onClick(): void
  InputProps?: any
  isAutocomplete?: boolean
  defaultValue?: string
}

export const CustomInput: React.FC<Props> = ({
  field,
  disabled,
  metaError,
  showError,
  placeholder,
  isAutocomplete,
  defaultValue,
  onClick,
  InputProps,
  ...params
}) => {
  const classes = useStyles()

  return (
    <div className={`${classes.singleInput} ${showError && classes.error}`}>
      <Transition
        items={showError}
        from={{
          opacity: 0,
          marginTop: "0px",
        }}
        enter={{ opacity: 1, marginTop: "4px" }}
        leave={{ opacity: 0, marginTop: "0px" }}
      >
        {(values, visible) => {
          return visible && <animated.p style={values}>{metaError}</animated.p>
        }}
      </Transition>
      {field.name === "postalCode" ? (
        <InputMask
          mask={field.value ? "99-999" : null}
          {...field}
          onClick={onClick}
          disabled={disabled === null ? false : disabled}
        >
          <StyledTextField label={field.value ? null : placeholder} />
        </InputMask>
      ) : field.name === "annualConsumption" ? (
        <MaskedStyledInput
          mask={Number}
          mapToRadix={["."]}
          radix=","
          scale={2}
          thousandsSeparator=""
          padFractionalZeros={true}
          label={field.value ? null : placeholder}
          {...field}
          min={0}
          max={500000}
          onClick={onClick}
          disabled={disabled === null ? false : disabled}
        />
      ) : (
        <StyledTextField
          InputProps={InputProps}
          label={field.value ? null : placeholder}
          className={isAutocomplete ? classes.Autocomplete : null}
          defaultValue={defaultValue}
          {...field}
          onClick={onClick}
          disabled={disabled === null ? false : disabled}
          {...params}
        />
      )}
    </div>
  )
}
