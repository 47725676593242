import React, { useState, useEffect } from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import {
  Dialog,
  DialogContent,
  useTheme,
  CircularProgress,
  useMediaQuery,
} from "@material-ui/core"
import { DatePicker } from "./datePicker/datePicker"
import StyledButton from "../Button"
import { DateRange } from "./datePicker/hourPicker/hourPicker"
import { greyColor } from "../../styles/constants"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: 0,
      overflow: "auto",
      "&:first-child": {
        padding: 0,
      },
    },
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "space-between",
      borderRadius: "20px",
      backgroundColor: "white",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "10px 0",
      "& h1": {
        margin: 0,
        fontSize: "38px",
      },
      "& h2": {
        color: greyColor,
        margin: 0,
        fontSize: "25px",
        fontWeight: theme.typography.fontWeightRegular,
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        "& h1": {
          fontSize: "32px",
        },
        "& h2": {
          fontSize: "20px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        "& h1": {
          fontSize: "26px",
        },
        "& h2": {
          fontSize: "17px",
        },
      },
    },
    buttonContainer: {
      display: "flex",
      alignSelf: "center",
      alignItems: "center",
      margin: "10px 0",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        justifyContent: "space-evenly",
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column-reverse",
      },
    },
    button: {
      width: "200px",
      height: "48px",
      margin: "0 10px",
      fontSize: "17px",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        margin: "10px 0",
      },
    },
    buttonProgress: {
      position: "absolute",
      width: "30px",
      height: "30px",
    },
  })
)

interface Props {
  show: boolean
  toggleModal: () => void
  onSubmit: (calendarData: DateRange) => void
}

export const MeetingModal: React.FC<Props> = ({
  toggleModal,
  show,
  onSubmit,
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [calendarData, setCalendarData] = useState(null)
  const [submiting, setSubmiting] = useState(false)

  useEffect(() => {
    setSubmiting(false)
  }, [show])

  const submit = async () => {
    onSubmit(calendarData)
  }

  return (
    <Dialog
      aria-describedby="meeting-modal"
      open={show}
      scroll={smallScreen ? "body" : "paper"}
      onClose={toggleModal}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.root}>
          <div className={classes.titleContainer}>
            <h1>Zaplanuj rozmowę</h1>
            <h2>
              Wybierz dogodny termin a nasz konsultant skontaktuje się z Tobą
            </h2>
          </div>
          <DatePicker setCalendarData={setCalendarData} />
          <div className={classes.buttonContainer}>
            <StyledButton
              variant="outlined"
              className={classes.button}
              onClick={() => toggleModal()}
              disabled={submiting}
            >
              Anuluj
            </StyledButton>
            <StyledButton
              color="primary"
              variant="contained"
              className={classes.button}
              disabled={submiting}
              onClick={() => {
                setSubmiting(true)
                submit()
              }}
            >
              Wyślij!
              {submiting && (
                <CircularProgress
                  className={classes.buttonProgress}
                  size="10"
                />
              )}
            </StyledButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
