import React, { useEffect, useState } from "react"
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  createStyles,
} from "@material-ui/core"
import {
  addDays,
  startOfMonth,
  endOfMonth,
  isSameMonth,
  isSameDay,
  isAfter,
  isBefore,
  set,
  getDay,
} from "date-fns"
import { eachDayOfInterval } from "date-fns/esm/fp"
import {
  getWorkingHours,
  periodLength,
} from "../../../sections/static-data/formularz"
import { getCurrentDay } from "../datePicker"
import { greyColor } from "../../../../styles/constants"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: theme.palette.primary.light,
      borderRadius: "10px",
      marginTop: "12px",
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
    table: {
      margin: "12px 0",
    },
    cell: {
      width: "54px",
      height: "54px",
      cursor: "default",
      color: "black",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: "16px",
      borderRadius: "50px",
      [theme.breakpoints.down("xs")]: {
        width: "40px",
        height: "40px",
        fontSize: "14px",
      },
    },
    dayNameCell: {
      padding: "8px 4px 20px",
    },
    dateCell: {
      border: 0,
    },
    differentMonth: {
      color: greyColor,
      opacity: 0.7,
    },
    dateContainer: {
      width: "44px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 auto",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        width: "32px",
        height: "32px",
      },
    },
    disabledDate: {
      color: greyColor,
      opacity: 0.2,
      cursor: "default",
    },
    selectedDate: {
      color: "white",
      borderRadius: "50px",
      background: theme.palette.primary.main,
      transition: "background 0.3s, color 0.2s",
    },
  })
)

interface Props {
  date?: Date
  setDate?: (date: Date) => void
  limit?: Date
}

export const Calendar: React.FC<Props> = ({ date, setDate, limit }) => {
  const classes = useStyles()
  const currentDay = getCurrentDay()
  const weekDays: string[] = ["Pon", "Wt", "Śr", "Czw", "Pt", "Sob", "Nd"]

  const [weeks, setWeeks] = useState([])
  const [currentDate, setCurrentDate] = useState(date || currentDay)

  useEffect(() => {
    const newWeeks = separateWeeks()
    setWeeks(newWeeks)
    setDate && setDate(currentDate)
  }, [currentDate])

  useEffect(() => {
    if (isAfter(date, currentDay) || isSameDay(date, currentDay)) {
      setCurrentDate(getWorkingDay(date))
    } else {
      setCurrentDate(currentDay)
    }
  }, [date])

  const getWorkingDay = (date: Date) => {
    const workingHours = getWorkingHours(getDay(date))

    return workingHours ? date : getWorkingDay(addDays(date, 1))
  }

  const createWeek = (days: Date[], selectedDay: Date) => {
    const week = []
    days.map(day =>
      week.push({
        day,
        selected: isSameDay(day, selectedDay) ? true : false,
        differentMonth: isSameMonth(day, selectedDay) ? false : true,
      })
    )
    return [week]
  }

  const separateWeeks = () => {
    const startOfMonthDay = startOfMonth(currentDate)
    const endOfMonthDay = endOfMonth(currentDate)
    const startDate = addDays(startOfMonthDay, -startOfMonthDay.getDay() + 1)
    const endDate = addDays(endOfMonthDay, 7 - endOfMonthDay.getDay())
    const daysToDisplay = eachDayOfInterval({ start: startDate, end: endDate })
    let weeksArray = []
    let start = 0
    let end = start + 7
    do {
      weeksArray = weeksArray.concat(
        createWeek(daysToDisplay.slice(start, end), currentDate)
      )
      start += 7
      end = start + 7
    } while (end <= daysToDisplay.length)
    return weeksArray
  }

  const disabledDay = (day: Date): boolean => {
    const workingHours = getWorkingHours(getDay(day))

    return (
      !workingHours ||
      (limit &&
        isBefore(
          day,
          set(currentDay, {
            hours: workingHours.endHour - 1,
            minutes: periodLength,
          })
        ) &&
        !isSameDay(day, currentDay)) ||
      isAfter(day, limit)
    )
  }

  return (
    currentDate && (
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="calendar">
          <TableHead>
            <TableRow>
              {weekDays.map((day, i) => {
                return (
                  <TableCell
                    className={`${classes.cell} ${classes.dayNameCell}`}
                    align="center"
                    key={i}
                  >
                    {day}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {weeks &&
              weeks.map((week, i) => {
                return (
                  <TableRow key={i}>
                    {week.map((el, ind) => {
                      return (
                        <TableCell
                          padding="none"
                          align="center"
                          key={ind}
                          className={`${classes.cell} ${classes.dateCell}`}
                        >
                          <div
                            onClick={() =>
                              !disabledDay(el.day) && setCurrentDate(el.day)
                            }
                            className={`
                            ${classes.dateContainer}
                            ${el.selected && classes.selectedDate}
                            ${disabledDay(el.day) && classes.disabledDate}
                            ${el.differentMonth && classes.differentMonth}`}
                          >
                            {el.day.getDate()}
                          </div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  )
}
