// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { graphql } from "gatsby"

import { Logos } from "./images/logos"
import { Logo } from "./images/logo"
import { makeStyles, Theme, createStyles } from "@material-ui/core"
import { maxWidth } from "../styles/constants"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      ...maxWidth,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "40px 0",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  })
)

const Header: React.FC = () => {
  const styles = useStyles()

  return (
    <header className={styles.header}>
      <Logo />
      <Logos />
    </header>
  )
}

export default Header
