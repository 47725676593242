import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles, Theme, createStyles } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: "58px",
      marginLeft: "25px",
      [theme.breakpoints.down("sm")]: {
        height: "40px",
        marginLeft: 0,
        marginRight: "12px",
      },
    },
  })
)

export const Logos = () => {
  const styles = useStyles()

  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativePath: { regex: "/logos.+/" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          publicURL
        }
      }
    }
  `)

  return (
    <div>
      {data.allFile.nodes.map(node => (
        <img
          alt="loga_partnerów"
          className={styles.logo}
          key={node.name}
          src={node.publicURL}
        />
      ))}
    </div>
  )
}
