import React, { useState } from "react"
import { makeStyles, Theme, useMediaQuery, Portal } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import { createStyles, useTheme } from "@material-ui/styles"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import { ContainerWithFixedBg } from "../containerWithFixedBg"
import Header from "../header"
import GwarancjaCenyImage from "../images/gwarancja-ceny"
import GwarancjaRabatuImage from "../images/gwarancja-rabatu"
import LandingImage from "../images/landing-bg"
import { MeetingModal } from "../meetingModal/meetingModal"
import StyledButton from "../Button"
import { Formularz } from "./formularz"
import { FormModal } from "../formModal/formModal"
import { DateRange } from "../meetingModal/datePicker/hourPicker/hourPicker"
import { SuccessModal } from "../successModal/successModal"
import { maxWidth, relativeContentPadding } from "../../styles/constants"
import scrollTo from "gatsby-plugin-smoothscroll"
import { gwarancje } from "./static-data/gwarancje"
import { ErrorModal } from "../errorModal/errorModal"
import Consumer from "../../context/Context"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overlay: {
      zIndex: 0,
    },
    topContainer: {
      ...relativeContentPadding,
    },
    cardsContainer: {
      ...maxWidth,
      display: "flex",
      alignItems: "flex-start",
      overflow: "hidden",
      flexDirection: "row",
      paddingBottom: "80px",
      "& .MuiCard-root": {
        margin: "20px 30px",
        flex: 1,
        [theme.breakpoints.down("lg")]: {
          margin: "20px 15px",
        },
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    formSection: {
      minWidth: "600px",
      maxWidth: "610px",
      margin: 0,
      marginRight: "-30px",
      [theme.breakpoints.down("lg")]: {
        marginRight: "-15px",
      },
      [theme.breakpoints.down("md")]: {
        width: "auto",
        margin: 0,
        maxWidth: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    formButton: {
      position: "fixed",
      bottom: "12px",
      right: "12px",
      width: "65px",
      height: "65px",
      borderRadius: "32.5px",
      zIndex: 999,
    },
    mainCard: {
      flex: "1 1 auto !important",
      width: "100%",
    },
    clickableCard: {
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
    },
    infoSection: {
      display: "flex",
      flexWrap: "wrap",
      flex: "1 1 auto",
      margin: 0,
      marginLeft: "-30px",
      "& h2": {
        textAlign: "center",
        marginBottom: "20px",
        marginTop: 0,
      },
      "& b": {
        marginBottom: "15px",
        display: "block",
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: "-15px",
      },
      [theme.breakpoints.down("md")]: {
        margin: 0,
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        margin: 0,
        "& .MuiCard-root": {
          margin: "20px 0",
          flex: 1,
          width: "100%",
        },
      },
    },
    infoImg: {
      height: "145px",
      marginBottom: "20px",
      borderRadius: "8px",
    },
  })
)

let dateResolver = null
let dateReject = null

export const MainSection: React.FC = () => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const [showCalendarModal, setShowCalendarModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [now, setNow] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  const getCalendarDataPromise = () => {
    setShowCalendarModal(true)
    return new Promise<DateRange>((resolve, reject) => {
      dateResolver = resolve
      dateReject = reject
    })
  }

  const submitDate = (value: DateRange) => {
    dateResolver(value)
  }

  return (
    <Consumer>
      {({ showForm, setShowForm }) => (
        <ContainerWithFixedBg
          ImageComponent={LandingImage}
          className={styles.topContainer}
        >
          <Header />
          <div className={styles.cardsContainer}>
            <div className={styles.infoSection}>
              <Card className={styles.mainCard}>
                <h1>Nowa oferta POLENERGIA! </h1>
                <p>
                  <span style={{ color: theme.palette.secondary.main }}>
                    Zielona Energia Dla Mobilności
                  </span>{" "}
                  to projekt, mający na celu udostępnienie{" "}
                  <span style={{ color: theme.palette.secondary.main }}>
                    Zielonej Energii
                  </span>{" "}
                  pochodzącej z&nbsp;odnawialnych źródeł energii Grupy
                  Polenergia, na potrzeby zasilania Twojego domu oraz ładowania
                  pojazdu elektrycznego lub hybrydowego.
                </p>
                <h1>Szczegóły oferty</h1>
                <p>
                  Zróbmy razem coś dobrego dla środowiska – wybierz ofertę{" "}
                  <span style={{ color: theme.palette.secondary.main }}>
                    Zielonej Energii
                  </span>{" "}
                  w&nbsp;opcji z&nbsp;Gwarancją Ceny lub Gwarancją Rabatu
                  i&nbsp;dołącz do grona osób dbających o&nbsp;redukcję śladu
                  węglowego!
                </p>
              </Card>
              <Card
                className={styles.clickableCard}
                onClick={() => scrollTo(`#${gwarancje[0].advAnchor}`)}
              >
                <h2>
                  Gwarancja{" "}
                  <span style={{ color: theme.palette.secondary.main }}>
                    Ceny
                  </span>
                </h2>
                <GwarancjaCenyImage
                  className={styles.infoImg}
                  relative={true}
                />
                <b>Zamrażamy ceny nawet na okres 5 lat!</b>
                <p>
                  Zastanawiasz się, ile będzie kosztowała energia elektryczna w
                  przyszłości? Chcesz mieć komfort zakupu energii po stałej
                  cenie w&nbsp;okresie 2, 3 a&nbsp;nawet 5 lat? Skorzystaj
                  z&nbsp;oferty Gwarancja Ceny i&nbsp;zabezpiecz się przed
                  wzrostem cen.
                </p>
              </Card>
              <Card
                className={styles.clickableCard}
                onClick={() => scrollTo(`#${gwarancje[1].advAnchor}`)}
              >
                <h2>
                  Gwarancja{" "}
                  <span style={{ color: theme.palette.secondary.main }}>
                    Rabatu
                  </span>
                </h2>
                <GwarancjaRabatuImage className={styles.infoImg} />

                <b>Zielona Energia zawsze taniej! </b>
                <p>
                  Jeśli skorzystasz z&nbsp;oferty Gwarancja Rabatu otrzymasz{" "}
                  <span style={{ color: theme.palette.primary.main }}>
                    Zieloną Energię{" "}
                  </span>
                  tańszą, co najmniej o&nbsp;5% lub 10% od taryfy Twojego
                  Sprzedawcy z&nbsp;Urzędu.
                </p>
              </Card>
              <Card className={styles.mainCard}>
                <h2
                  style={{
                    margin: "10px 0",
                    fontWeight: theme.typography.fontWeightLight,
                  }}
                >
                  W&nbsp;przypadku obu ofert energia pochodzi w{" "}
                  <span
                    style={{
                      color: theme.palette.secondary.main,
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    100% z&nbsp;Odnawialnych Źródeł Energii{" "}
                  </span>
                  <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                    należących do Grupy Polenergia.
                  </span>
                </h2>
              </Card>
            </div>
            <ErrorModal
              show={showErrorModal}
              toggleModal={() => {
                setShowErrorModal(false)
              }}
              error={errorMsg}
            />
            <SuccessModal
              show={showSuccessModal}
              toggleModal={() => {
                setShowSuccessModal(false)
              }}
              now={now}
            />
            <MeetingModal
              show={showCalendarModal}
              toggleModal={() => {
                setShowCalendarModal(false)
                dateReject()
              }}
              onSubmit={submitDate}
            />
            {smallScreen ? (
              <>
                <FormModal
                  show={showForm}
                  toggleModal={() => setShowForm(!showForm)}
                  getCalendarValue={() => getCalendarDataPromise()}
                  onSucces={now => {
                    setShowSuccessModal(true)
                    setShowCalendarModal(false)
                    setShowForm(false)
                    setNow(now)
                  }}
                  onError={errMsg => {
                    setErrorMsg(errMsg)
                    setShowSuccessModal(false)
                    setShowCalendarModal(false)
                    setShowForm(false)
                    setShowErrorModal(true)
                  }}
                />
                <Portal>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    className={styles.formButton}
                    onClick={() => setShowForm(true)}
                  >
                    <MailOutlineIcon />
                  </StyledButton>
                </Portal>
              </>
            ) : (
              <div className={styles.formSection}>
                <Card>
                  <Formularz
                    getCalendarValue={() => getCalendarDataPromise()}
                    onSucces={now => {
                      setShowCalendarModal(false)
                      setShowSuccessModal(true)
                      setNow(now)
                    }}
                    onError={errMsg => {
                      setErrorMsg(errMsg)
                      setShowSuccessModal(false)
                      setShowCalendarModal(false)
                      setShowForm(false)
                      setShowErrorModal(true)
                    }}
                  />
                </Card>
              </div>
            )}
          </div>
        </ContainerWithFixedBg>
      )}
    </Consumer>
  )
}
