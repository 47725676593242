import React, { useState } from "react"
import {
  Checkbox,
  Theme,
  withStyles,
  withTheme,
  makeStyles,
  Collapse,
} from "@material-ui/core"
import { FormTypes } from "./sections/static-data/formularz"
import CheckIcon from "@material-ui/icons/Check"
import { Transition, animated } from "react-spring"

export const StyledCheckbox = withStyles((theme: Theme) => ({
  root: {
    height: "23.5px",
    width: "23.5px",
    border: 0,
    borderRadius: "5px",
    color: theme.palette.primary.main,
    backgroundColor: "#F9F6F6",
    boxShadow:
      "inset -2px -2px 7px rgba(255, 255, 255, 0.82), inset 1px 1px 5px rgba(61, 61, 61, 0.25)",
    "&:hover": {
      backgroundColor: "#F9F6F6",
    },
    "& svg": {
      width: "20px",
    },
    "&.Mui-checked": {
      height: "23.5px",
      width: "23.5px",
      border: `1.5px solid ${theme.palette.primary.main}`,
    },
  },
}))(withTheme(Checkbox))

export default StyledCheckbox

const useStyles = makeStyles((theme: Theme) => ({
  consentRequired: {
    color: theme.palette.error.main,
  },
  consentError: {
    color: theme.palette.error.main,
    display: "block",
  },
  toggler: {
    textDecoration: "none",
    outline: "none",
    padding: 0,
    color: theme.palette.secondary.main,
    cursor: "pointer",
    fontSize: "12px",
    transition: "color 0.15s ease-in-out",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  animatedDiv: {
    fontSize: "11px",
    marginLeft: "12px",
  },
}))
interface CustomCheckboxProps {
  field: {
    value: boolean
  }
  consent: FormTypes
  error: string
  showError: boolean
}

export const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  field,
  consent,
  error,
  showError,
}) => {
  const classes = useStyles()
  const [show, setShow] = useState(false)

  return (
    <>
      <StyledCheckbox
        icon={<div />}
        checked={Boolean(field.value)}
        checkedIcon={<CheckIcon />}
        disableRipple
        {...field}
      />
      <div>
        <p style={{ fontSize: "12.5px", marginBottom: "4px" }}>
          {consent.required && (
            <span className={classes.consentRequired}>* </span>
          )}
          {consent.placeholder}{" "}
          <a
            href=""
            className={classes.toggler}
            onClick={e => {
              e.preventDefault()
              setShow(!show)
            }}
          >
            {show ? "Zwiń" : "Rozwiń"}
          </a>
        </p>

        <Collapse in={show}>
          <div className={classes.animatedDiv}>{consent.description}</div>
        </Collapse>

        <Transition
          items={showError}
          from={{
            opacity: 0,
            height: "0px",
          }}
          enter={{ opacity: 1, height: "15px" }}
          leave={{ opacity: 0, height: "0px" }}
        >
          {(values, visible) => {
            return (
              visible && (
                <animated.p style={values} className={classes.consentError}>
                  {error}
                </animated.p>
              )
            )
          }}
        </Transition>
      </div>
    </>
  )
}
