import React from "react"
import SEO from "../seo"
import { ContainerWithFixedBg } from "../containerWithFixedBg"
import LandingImage from "../images/landing-bg"
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: "90px",
      [theme.breakpoints.down("sm")]: {
        height: "60px",
      },
    },
    container: {
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    placeholderContainer: {
      textAlign: "center",
    },
    label: {
      margin: 100,
      marginTop: 0,
      fontSize: 40,
      [theme.breakpoints.down("sm")]: {
        margin: 10,
      },
    },
  })
)

export interface OffMessageProps {
  message: string
}

export const OffMessage: React.FC<OffMessageProps> = (
  props: OffMessageProps
) => {
  const styles = useStyles()

  const LogoSmall = () => {
    const data = useStaticQuery(graphql`
      {
        file(relativePath: { eq: "logo_xl.png" }) {
          publicURL
        }
      }
    `)
    return (
      <img
        alt="polenergia_logo"
        className={styles.logo}
        src={data.file.publicURL}
      />
    )
  }

  return (
    <>
      <SEO title="" />
      <div className={styles.container}>
        <ContainerWithFixedBg
          className={"noPadding"}
          ImageComponent={LandingImage}
          fixed={true}
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
          >
            <div className={styles.placeholderContainer}>
              <Typography className={styles.label}>{props.message}</Typography>
              <LogoSmall />
            </div>
          </Grid>
        </ContainerWithFixedBg>
      </div>
    </>
  )
}
