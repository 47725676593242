import React, { useState, useEffect } from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core"
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined"
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined"
import { FilledButton } from "../../../Button"
import { subMonths, addMonths, isSameMonth, format } from "date-fns"
import { pl } from "date-fns/locale"
import { greyColor } from "../../../../styles/constants"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    monthContainer: {
      display: "flex",
      background: theme.palette.primary.light,
      borderRadius: "15px",
      height: "46px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    textContainer: {
      width: "310px",
      textAlign: "center",
      alignSelf: "center",
      fontSize: "18px",
      fontWeight: theme.typography.fontWeightMedium,
      padding: "2px 0",
      color: greyColor,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        fontSize: "15px",
        padding: "2px 4px",
      },
    },
    monthName: {
      fontWeight: theme.typography.fontWeightBold,
      paddingLeft: "6px",
      textTransform: "capitalize",
      color: "black",
    },
    buttonDisabled: {
      background: "lightGrey",
      [theme.breakpoints.down("xs")]: {
        width: "24px",
      },
    },
    buttonNormal: {
      cursor: "pointer",
      "&:hover": {
        background: theme.palette.primary.dark,
      },
      transition: "background 0.2s ease-in-out",
      [theme.breakpoints.down("xs")]: {
        width: "24px",
      },
    },
    buttonPrev: {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    buttonNext: {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  })
)

interface Props {
  date?: Date
  setDate?: (date: Date) => void
  limit?: Date
}

export const Month: React.FC<Props> = ({ date, setDate, limit }) => {
  const classes = useStyles()
  const currentDay = new Date()
  const [currentDate, setCurrentDate] = useState(date || currentDay)

  useEffect(() => {
    setDate && setDate(currentDate)
  }, [currentDate])

  useEffect(() => {
    setCurrentDate(date)
  }, [date])

  const monthName = (date: Date): string => {
    const formatted = format(date, "LLLL", { locale: pl })
    return formatted
  }

  const previousMonth = () => {
    const newDate = subMonths(currentDate, 1)
    setCurrentDate(newDate)
  }

  const nextMonth = () => {
    const newDate = addMonths(currentDate, 1)
    setCurrentDate(newDate)
  }

  const prevDisabled: boolean = isSameMonth(currentDate, currentDay)
  const nextDisabled: boolean = isSameMonth(currentDate, limit)

  return (
    <div className={classes.monthContainer}>
      <FilledButton
        className={`${classes.buttonPrev} ${
          prevDisabled ? classes.buttonDisabled : classes.buttonNormal
        }`}
        onClick={() => !prevDisabled && previousMonth()}
        disabled={prevDisabled}
      >
        <ArrowBackIosOutlinedIcon htmlColor="white" />
      </FilledButton>
      <h4 className={classes.textContainer}>
        Wybrany miesiąc:
        <span className={classes.monthName}>{monthName(currentDate)}</span>
      </h4>
      <FilledButton
        className={`${classes.buttonNext} ${
          nextDisabled ? classes.buttonDisabled : classes.buttonNormal
        }`}
        onClick={() => !nextDisabled && nextMonth()}
        disabled={nextDisabled}
      >
        <ArrowForwardIosOutlinedIcon htmlColor="white" />
      </FilledButton>
    </div>
  )
}
