import React from "react"

import GwarancjaCenyImage from "../../images/gwarancja-ceny"
import GwarancjaRabatuImage from "../../images/gwarancja-rabatu"
import { CennikCena } from "../cenniki"
import theme from "../../../styles/theme"

export interface Doc {
  path: string
  title: string | JSX.Element
}

export const gwarancje = [
  {
    header: "Ceny",
    Bg: GwarancjaCenyImage,
    advAnchor: "cena",
    advHeader: "Zalety gwarancji ceny:",
    advContent: (
      <>
        • Otrzymujesz stałą cenę energii z&nbsp;dnia podpisania Umowy przez
        wybrany przez Ciebie okres 2, 3 lub 5 lat <br /> <br />•{" "}
        <span style={{ color: theme.palette.primary.main }}>
          Zielona Energia{" "}
        </span>
        pochodzi z&nbsp;polskich Odnawialnych Źródeł Energii należących do Grupy
        Polenergia
        <br /> <br />• Ładując samochód elektryczny w&nbsp;domu zawsze wiesz,
        ile kosztuje Cię jego naładowanie i&nbsp;nie martwisz się podwyżkami
        energii na rynku
      </>
    ),
    prices: [
      {
        group: "G11",
        rate: "Stawka zmienna (całodobowa)",
        ratioNet: 0.293,
        ratioGross: 0.3604,
      },
      {
        group: "G12",
        rate: "Stawka zmienna (Strefa I - dzień)",
        ratioNet: 0.3382,
        ratioGross: 0.416,
      },
      {
        group: "G12",
        rate: "Stawka zmienna (Strefa II - noc)",
        ratioNet: 0.235,
        ratioGross: 0.2891,
      },
      {
        group: "Pozostałe grupy taryfowe",
        rate: "Stawka zmienna (całodobowa)",
        ratioNet: 0.293,
        ratioGross: 0.3604,
      },
    ],
    PricesComponent: CennikCena,
    docsHeader: "Cenniki Gwarancji Ceny",
    docs: [
      {
        title: "Cennik obecny Klient",
        path:
          "/Cennik G - sieć POLD komplex - gwarancja ceny emobility ZIELONY.pdf",
      },
      {
        title: "Cennik nowy Klient",
        path: "/Cennik G - TPAz US - gwarancja ceny emobility ZIELONY.pdf",
      },
    ],
  },
  {
    header: "Rabatu",
    Bg: GwarancjaRabatuImage,
    advAnchor: "rabat",
    advHeader: "Zalety gwarancji rabatu:",
    advContent: (
      <>
        • Otrzymujesz{" "}
        <span style={{ color: theme.palette.primary.main }}>
          Zieloną Energię{" "}
        </span>
        taniej, niż energia konwencjonalna od Twojego sprzedawcy
        <br /> <br />
        • Sam określasz okres trwania Umowy i&nbsp;poziom rabatu
        <br /> <br />• Wykorzystujesz{" "}
        <span style={{ color: theme.palette.primary.main }}>
          Zieloną Energię{" "}
        </span>
        zarówno na potrzeby Twojego samochodu elektrycznego oraz domu
      </>
    ),
    docsHeader: "Cenniki Gwarancji Rabatu",
    docs: [
      {
        title: (
          <>
            Cennik 5% <br /> obecny Klient
          </>
        ),
        path:
          "/Cennik G - sieć POLD komplex - gwarancja rabatu 5procent emobility ZIELONY.pdf",
      },
      {
        title: (
          <>
            Cennik 10% <br /> obecny Klient
          </>
        ),
        path:
          "/Cennik G - sieć POLD komplex - gwarancja rabatu 10procent emobility ZIELONY.pdf",
      },
      {
        title: (
          <>
            Cennik 5%
            <br /> nowy Klient <br />
            OSD Innogy
          </>
        ),
        path:
          "/Cennik G - TPAz US - gwarancja rabatu 5procent emobility (OSD Innogy) ZIELONY.pdf",
      },

      {
        title: (
          <>
            Cennik 10%
            <br /> nowy Klient <br />
            OSD Innogy
          </>
        ),
        path:
          "/Cennik G - TPAz US - gwarancja rabatu 10procent emobility (OSD Innogy) ZIELONY.pdf",
      },
      {
        title: (
          <>
            Cennik 5%
            <br /> nowy Klient <br /> pozostałe OSD
          </>
        ),
        path:
          "/Cennik G - TPAz US - gwarancja rabatu 5procent emobility (PGE, Tauron, Enea, Energa) ZIELONY.pdf",
      },
      {
        title: (
          <>
            Cennik 10%
            <br /> nowy Klient <br /> pozostałe OSD
          </>
        ),
        path:
          "/Cennik G - TPAz US - gwarancja rabatu 10procent emobility (PGE, Tauron, Enea, Energa) ZIELONY.pdf",
      },
    ],
  },
]

export const wspolneDokumenty = [
  {
    title: "Odstąpienie od umowy",
    path: "/odstąpienie od umowy.pdf",
  },
  {
    title: "Ogólne warunki sprzedaży",
    path: "/OWS - TPAz.pdf",
  },
  {
    title: "Pełnomocnictwo do zmiany sprzedawcy",
    path: "/pelnomocnictwo zmiana sprzedawcy.pdf",
  },
  {
    title: "Regulamin promocji",
    path: "/Regulamin_Promocji_Darmowy_Pakiet_Zielonej_Energii emobility.pdf",
  },
  {
    title: "Wzór umowy",
    path: "/UMOWA SPRZEDAŻY - TPAz - konsum-dzialalnosc.pdf",
  },
  {
    title: (
      <>
        Wybór oferty <br /> obecny Klient
      </>
    ),
    path: "/wybór oferty - zgody RODO - klauzula - sieć POLD - emobility.pdf",
  },
  {
    title: (
      <>
        Wybór oferty <br /> nowy Klient
      </>
    ),
    path: "/wybór oferty - zgody RODO - klauzula - TPAz - emobility.pdf",
  },
]
