import React, { useState, useEffect } from "react"
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core"

import Card from "@material-ui/core/Card"
import { useTheme, createStyles } from "@material-ui/styles"
import { ContainerWithFixedBg } from "../containerWithFixedBg"
import InformacjaBg from "../images/informacja-bg"
import StyledButton from "../Button"
import { maxWidth } from "../../styles/constants"
import { informationContent } from "./static-data/informacje"
import { greyColor } from "../../styles/constants"
import { Transition, animated } from "react-spring"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionContainer: {
      paddingTop: "60px",
      paddingBottom: "60px",
    },
    card: {
      ...maxWidth,
      textAlign: "center",
      maxWidth: "920px",
      margin: "auto",
      padding: "50px 40px",
      "& h1": {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: "43px",
        margin: 0,
        "& span": {
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      [theme.breakpoints.down("sm")]: {
        padding: "30px 16px",
        textAlign: "left",
        "& h1": {
          fontSize: "36px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        "& h1": {
          fontSize: "30px",
        },
      },
    },
    infoSection: {
      height: "246px",
      overflow: "auto",
      margin: "20px 0 0",
      color: greyColor,
      [theme.breakpoints.down("sm")]: {
        height: "200px",
      },
      "& p": {
        margin: 0,
        paddingRight: "4px",
        fontSize: "21px",
        letterSpacing: "0.15px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "17px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px",
        },
      },
    },
    img: {
      margin: "-10px -70px -10px 30px",
      width: "650px",
    },
    button: {
      margin: "32px 10px 0",
      fontSize: "17px",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        fontSize: "15px",
        margin: "20px auto 0",
        width: "50vw",
      },
      [theme.breakpoints.down("xs")]: {
        width: "70vw",
        height: "36px",
        fontSize: "14px",
      },
    },
    backButton: {
      [theme.breakpoints.down("sm")]: {
        height: "48px",
        margin: "52px auto 22px",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "47px auto 17px",
      },
    },
  })
)

export const InformacjaSection: React.FC = () => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const [infoType, setInfoType] = useState("info")
  const [displayedInfo, setDisplayedInfo] = useState<JSX.Element>()

  useEffect(() => {
    const content = informationContent(infoType)
    setDisplayedInfo(content)
  }, [infoType])

  const infoTranslate = (type: string): string => {
    switch (type) {
      case "polenergia":
        return "translate3d(10%, 0, 0)"
      case "vw":
        return "translate3d(-10%, 0, 0)"
      default:
        return "translate3d(0, -10%, 0)"
    }
  }

  return (
    <ContainerWithFixedBg
      ImageComponent={InformacjaBg}
      className={styles.sectionContainer}
    >
      <Card className={styles.card}>
        <h1>
          Sprawdź jak <span>Volkswagen Group Polska </span>i&nbsp;
          <span>Grupa Polenergia</span> dbają o&nbsp;środowisko!
        </h1>

        <Transition
          items={displayedInfo}
          from={{ opacity: 0.75, transform: infoTranslate(infoType) }}
          enter={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
        >
          {(values, visible) => {
            return (
              visible && (
                <animated.div style={values} className={styles.infoSection}>
                  <p>{displayedInfo}</p>
                </animated.div>
              )
            )
          }}
        </Transition>

        {infoType === "info" ? (
          <>
            <StyledButton
              color="primary"
              variant="contained"
              onClick={() => setInfoType("vw")}
              className={styles.button}
            >
              Volkswagen Group Polska
            </StyledButton>
            <StyledButton
              color="primary"
              variant="contained"
              onClick={() => setInfoType("polenergia")}
              className={styles.button}
            >
              Grupa Polenergia
            </StyledButton>
          </>
        ) : (
          <StyledButton
            color="primary"
            variant="contained"
            onClick={() => setInfoType("info")}
            className={`${styles.button} ${styles.backButton}`}
          >
            Powrót
          </StyledButton>
        )}
      </Card>
    </ContainerWithFixedBg>
  )
}
