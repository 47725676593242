import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { Dialog, DialogContent, useTheme, Typography } from "@material-ui/core"
import StyledButton from "../Button"
import SuccessImage from "../images/success"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      padding: 0,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      "& h1": {
        margin: 0,
        fontSize: "38px",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "& h3": {
        margin: "25px 0 0",
        fontSize: "15px",
        color: "#656D76",
        opacity: 0.55,
      },
      [theme.breakpoints.down("sm")]: {
        "& h1": {
          fontSize: "30px",
        },
        "& h3": {
          fontSize: "14px",
        },
      },
    },
    image: {
      height: "212px",
      width: "444px",
      maxWidth: "60vw",
      margin: "30px 0 0",
      borderRadius: "8px",
    },
    button: {
      display: "block",
      margin: "40px 0 12px",
      width: "225px",
      height: "48px",
      fontSize: "17px",
      [theme.breakpoints.down("xs")]: {
        width: "45vw",
      },
    },
  })
)

interface Props {
  show: boolean
  toggleModal: () => void
  now: boolean
}

export const SuccessModal: React.FC<Props> = ({ toggleModal, show, now }) => {
  const classes = useStyles()

  return (
    <Dialog
      aria-describedby="form-modal"
      open={show}
      onClose={toggleModal}
      fullWidth
      className={classes.contentWrapper}
    >
      <DialogContent className={classes.content}>
        <h1>Rozmowa zaplanowana!</h1>
        <SuccessImage className={classes.image} />
        <h3>
          Dziękujemy - zadzwonimy do Ciebie{" "}
          {now ? "najszybciej jak to możliwe" : "w wybranym terminie"}
          <p>Do usłyszenia!</p>
        </h3>
        <StyledButton
          color="primary"
          variant="contained"
          onClick={() => toggleModal()}
          className={classes.button}
        >
          Powrót
        </StyledButton>
      </DialogContent>
    </Dialog>
  )
}
