export enum EDocumentType {
  NONE = "None",
  PESEL = "Pesel",
  PASSPORT = "Passport",
}

export enum EAddressType {
  NONE = "None",
  PPE = "Ppe",
  CORRESPONDENCE = "Correspondence",
}

export enum ECorrespondenceAddressType {
  NONE = "None",
  ADDRESS = "Address",
}

export enum EStatementType {
  NONE = "None",
  SELF = "Self",
  PROXY = "Proxy",
  PARTNER = "Partner",
}

export enum EPartnerAddressType {
  NONE = "None",
  CUSTOMER = "Customer",
}

export enum EOwnershipType {
  NONE = "None",
  YES = "Yes",
  NO = "NoButIHasObject",
}

export enum EOwnershipSourceType {
  NONE = "None",
  LAND_REGISTER = "LandRegister",
  NOTARIAL_ACT = "NotarialAct",
  LEASE_AGREEMENT = "LeaseAgreement",
  OTHER = "Other",
}

export enum EEnergyDestinationType {
  NONE = "None",
  FLAT = "Flat",
  HOUSE = "House",
  TARRACED_HOUSE = "TarracedHouse",
  OTHER = "Other",
}

export enum EJdgEnergyDestinationType {
  NONE = "None",
  RENTING = "Renting",
  POWERSUPPLY = "PowerSupply",
  FREELANCER_PROFESSION = "FreelanceProfession",
  OTHER = "Other",
}

export enum EEInvoiceType {
  NONE = "None",
  YES = "Yes",
  NO = "No",
}

export enum EEInvoiceAddressType {
  NONE = "None",
  CLIENT = "Client",
}

export enum EAggreeType {
  NONE = "None",
  YES_CLIENT = "YesClient",
  NO = "No",
}

export enum EConsentAggreeType {
  NONE = "None",
  YES = "Yes",
  NO = "No",
}
export enum EOfferDuration {
  NONE = "None",
  TWO = "Two",
  THREE = "Three",
}

export enum EOfferType {
  STANDARD = "Standard",
}

export enum EServiceType {
  NONE = "None",
}

export enum EServiceOptionType {
  NONE = "None",
}

export enum EOfferDocumentsAnhor {
  STANDARD = "standard-documents",
  COMMON = "common-documents",
}

export enum EDocumentVersion {
  NONE,
  PZO,
  OT,
}

export enum ELandingPage {
  NEW_CUSTOMER,
  CURRENT_CUSTOMER,
  EMOBILITY,
}

export enum EPlugInType {
  NONE = "None",
  YES = "Yes",
  NO = "No",
}

export enum EWallBoxType {
  NONE = "None",
  YES = "Yes",
  NO = "No",
}
export enum EFormTypes {
  EMOBILITY,
  PESEL,
  SERVER_ERROR,
}
