import React from "react"
import { theme } from "../../../styles/theme"

export const getWorkingHours = (dayOfWeek: number) => {
  switch (dayOfWeek) {
    case 6:
    case 0:
      return null
    default:
      return {
        startHour: 8,
        endHour: 18,
      }
  }
}
export const periodLength = 30

export interface ContactFormValues {
  commercialClient: boolean
  companyName: string
  taxNumber: string
  firstName: string
  lastName: string
  phone: string
  email: string
  postalCode: string
  annualConsumption: string
  currentSupplier: string
  file: File
}

export interface FormTypes {
  disabled?: boolean
  name: string
  description?: JSX.Element | string
  placeholder?:
    | string
    | React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      >
  required?: boolean
  validation?: (el: string) => boolean
  errorMsg?: string
  errorEmpty?: string
  width?: boolean | "auto" | 1 | 2 | 4 | 8 | 12 | 10 | 3 | 5 | 6 | 7 | 9 | 11
  conditionalHidden?: (formValue: ContactFormValues) => boolean
  simpleContactFormValue?: boolean
  defaultValue?: string | boolean
  conditionalPlaceholder?: (
    val: any
  ) =>
    | string
    | React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      >
}

export const FormID: string = "formularz"

export const inputFields: FormTypes[] = [
  {
    name: "companyName",
    placeholder: "Nazwa firmy",
    validation: el => el.length < 2,
    errorMsg: "Wprowadź co najmniej 2 znaki",
    errorEmpty: "Podaj nazwę firmy",
    conditionalHidden: (val: ContactFormValues) => val.commercialClient,
    simpleContactFormValue: false,
    required: true,
  },
  {
    name: "taxNumber",
    placeholder: "NIP",
    validation: el =>
      el.length < 10 || el.length > 15 || !/^[A-Z0-9-]+$/i.test(el),
    errorMsg: "Niepoprawny NIP",
    errorEmpty: "Podaj NIP",
    conditionalHidden: (val: ContactFormValues) => val.commercialClient,
    simpleContactFormValue: false,
    required: true,
  },
  {
    name: "firstName",
    placeholder: "Imię",
    validation: el => el.length < 2,
    errorMsg: "Wprowadź co najmniej 2 znaki",
    errorEmpty: "Podaj imię",
    simpleContactFormValue: true,
    required: true,
  },
  {
    name: "lastName",
    placeholder: "Nazwisko",
    validation: el => el.length < 2,
    errorMsg: "Wprowadź co najmniej 2 znaki",
    errorEmpty: "Podaj nazwisko",
    simpleContactFormValue: true,
    required: true,
  },
  {
    name: "phone",
    placeholder: "Telefon",
    validation: el =>
      !/^(([+][0-9]{2}|[0]{2}[0-9]{2}){0,1}[1-9][0-9]{8})$/i.test(el),
    errorMsg: "Niepoprawny numer telefonu",
    errorEmpty: "Podaj numer telefonu",
    simpleContactFormValue: true,
    required: true,
  },
  {
    name: "email",
    placeholder: "Email",
    validation: el => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(el),
    errorMsg: "Niepoprawny email",
    errorEmpty: "Podaj email",
    simpleContactFormValue: false,
    required: true,
  },
  {
    name: "postalCode",
    placeholder: "Kod pocztowy",
    validation: el => !/^([0-9]{2}-[0-9]{3})$/i.test(el),
    errorMsg: "Niepoprawny kod",
    errorEmpty: "Podaj kod pocztowy",
    width: 4,
    simpleContactFormValue: false,
    required: true,
  },
  {
    name: "annualConsumption",
    placeholder: (
      <div style={{ letterSpacing: "0.65px" }}>
        {"Roczne zużycie energii [kWh] "}
        <span style={{ opacity: 0.5, letterSpacing: "0.5px" }}>
          {"(opcjonalne)"}
        </span>
      </div>
    ),
    validation: el => !/^([0-9]*([,.][0-9]{0,2}){0,1})$/i.test(el),
    errorMsg: "Niepoprawny format",
    simpleContactFormValue: false,
    width: 8,
  },
  {
    name: "currentSupplier",
    placeholder: (
      <div>
        {"Obecny sprzedawca energii "}
        <span style={{ opacity: 0.5, letterSpacing: "0.5px" }}>
          {"(opcjonalne)"}
        </span>
      </div>
    ),
    width: 12,
    simpleContactFormValue: false,
  },
]

export const consents = [
  {
    name: "tel_contact",
    placeholder: "Zgoda na kontakt telefoniczny",
    description: (
      <span>
        Wyrażam zgodę na kontakt ze mną przez Polenergia Dystrybucja
        sp.&nbsp;z&nbsp;o.o. za pośrednictwem telefonu (zarówno w&nbsp;formie
        połączeń głosowych, jak i&nbsp;wiadomości SMS oraz MMS), w&nbsp;tym
        z&nbsp;pomocą automatycznych systemów wywołujących, w&nbsp;celu
        informowania mnie o&nbsp;ofertach dotyczących obecnych i&nbsp;przyszłych
        produktów i&nbsp;usług spółek z&nbsp;Grupy POLENERGIA.
      </span>
    ),
    required: true,
    simpleContactFormValue: true,
  },
  {
    name: "e_contact",
    placeholder: "Zgoda na kontakt drogą elektroniczną",
    description: (
      <span>
        Wyrażam zgodę na przesyłanie przez Polenergia Dystrybucja
        sp.&nbsp;z&nbsp;o.o. informacji handlowej związanej z&nbsp;obsługą
        Klienta, saldem należności oraz ofertami dotyczącymi obecnych
        i&nbsp;przyszłych produktów i&nbsp;usług spółek z&nbsp;Grupy POLENERGIA,
        za pomocą środków komunikacji elektronicznej w&nbsp;postaci poczty
        elektronicznej lub komunikatorów internetowych.
      </span>
    ),
    simpleContactFormValue: false,
    required: false,
  },
]

export const consentFileds: FormTypes[] = [
  ...Object.keys(consents).map(key => {
    const consent = consents[key]
    return {
      name: consent.name,
      placeholder: consent.placeholder,
      description: consent.description,
      required: consent.required,
      errorEmpty: "Zgoda jest wymagana",
      simpleContactFormValue: consent.simpleContactFormValue,
    }
  }),
]

export const fileField: FormTypes = {
  name: "file",
  simpleContactFormValue: false,
  conditionalPlaceholder: file =>
    file ? (
      <p>
        Plik{" "}
        <span style={{ fontWeight: theme.typography.fontWeightBold }}>
          {file.file.name}{" "}
        </span>
        został załączony
      </p>
    ) : (
      <p>
        Skan lub zdjęcie aktualnej umowy z&nbsp;Twoim obecnym dostawca energii
        elektrycznej
      </p>
    ),
}

export const contactFormFields: FormTypes[] = [
  ...inputFields,
  ...consentFileds,
  fileField,
]

export const placeholdersDict = contactFormFields.reduce((acc, field) => {
  acc[field.name] = field.placeholder
  return acc
}, {})
