import React from "react"
import {
  makeStyles,
  Typography,
  Theme,
  createStyles,
  Grid,
} from "@material-ui/core"
import { maxWidth } from "../styles/constants"
import { newCustomerOfferData } from "./sections/static-data/newCustomerOffer"
import { currentCustomerOfferData } from "./sections/static-data/currentCustomerOffer"
import { OffersPaper, OffersPaperSection } from "../models/OffersInterfaces"
import { ELandingPage } from "../models/OffersEnums"
import { MyPoleneBannerFooter } from "./sections/myPoleneBannerFooter"
import { MyPoleneBanner } from "./sections/myPoleneBanner"
import { DocumentIcon } from "./controls/DocumentIcon"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      padding: "0px 10px",
      textAlign: "center",
      backgroundColor: "#2a2a2a",
    },
    footerContainer: {
      ...maxWidth,
      margin: "auto",
      [theme.breakpoints.down("lg")]: {
        paddingLeft: "100px",
        paddingRight: "100px",
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    },
    copyrightsLeft: {
      fontSize: "1.1rem",
      color: "white",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    copyrightsRight: {
      fontSize: "1.1rem",
      color: "white",
      textAlign: "right",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    link: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "none",
    },
    bold: {
      fontWeight: 800,
    },
    phone: {
      color: "#f2a900",
      fontSize: "22px",
      fontWeight: 800,
      "& a": {
        color: "#f2a900",
        textDecoration: "none",
      },
    },
    documentsFooter: {
      backgroundColor: "#F2F2F2",
    },
    documentSectionHeader: {
      marginTop: "24px",
      marginBottom: "24px",
      textAlign: "center",
      "& p": {
        textAlign: "left",
        fontSize: "31px",
        "& span": {},
      },
    },
    offerHeader: {
      fontSize: "34px;",
      marginTop: "11px",
      marginBottom: "8px",
    },
    offerTitleAccent: {
      color: "#12beab",
    },
    cardsContainer: {
      ...maxWidth,
      display: "flex",
      alignItems: "flex-start",
      overflow: "hidden",
      flexDirection: "row",
      paddingTop: "20px",
      "& .MuiCard-root": {
        flex: 1,
        [theme.breakpoints.down("lg")]: {
          margin: "20px 15px",
        },
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    maxGridHeight: {},
    offerPaperCard: {
      textAlign: "center",
      height: "100%",
      "& p": {
        fontSize: "16px",
        margin: "0px",
        paddingTop: "14px",
        paddingBottom: "14px",
      },
    },
    documentsFooterWrapper: {
      ...maxWidth,
      margin: "auto",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
    documentsFooterContainer: {
      paddingRight: "20px",
      paddingLeft: "20px",
    },
    documentsFooterBorder: {
      borderLeft: "1px solid #12BEAB",
      borderRight: "1px solid #12BEAB",
      [theme.breakpoints.down("lg")]: {
        borderWidth: "0px",
      },
    },
    footerContentPadding: {
      paddingLeft: "140px",
      paddingRight: "140px",
    },
    documentWrapper: {
      display: "flex",
    },
    documentContainer: {
      padding: "8px",
      boxShadow: "2px 4px 32px 0 rgba(28, 28, 28, 0.16)",
      backgroundColor: "#fff",
      margin: "auto",
      borderRadius: "24px",
      height: "48px",
      width: "48px",
    },
  })
)

export interface IFooterProps {
  showSummary?: boolean
  landingPage: ELandingPage
  hideDocuments?: boolean
}

const Footer: React.FC<IFooterProps> = ({
  showSummary,
  landingPage,
  hideDocuments,
}) => {
  const styles = useStyles()

  const documents =
    landingPage === ELandingPage.CURRENT_CUSTOMER
      ? currentCustomerOfferData[0].documents
      : newCustomerOfferData[0].documents

  return (
    <>
      {showSummary ? (
        <MyPoleneBanner />
      ) : (
        landingPage === ELandingPage.CURRENT_CUSTOMER && (
          <MyPoleneBannerFooter />
        )
      )}
      {!hideDocuments && (
        <div className={styles.documentsFooter}>
          <div className={styles.documentsFooterWrapper}>
            <div className={styles.documentsFooterBorder}>
              <div className={styles.documentsFooterContainer}>
                {documents.map((document: OffersPaperSection, index) => {
                  return (
                    <Grid
                      id={document.id}
                      container
                      justify="center"
                      spacing={2}
                      key={`${index}-${document.id}`}
                    >
                      <Grid item xs={12} lg={2}>
                        <div className={styles.documentSectionHeader}>
                          {(() => {
                            var parts: string[] = document.header.split(" ")
                            var normalText: string = parts.slice(1).join(" ")
                            return (
                              <p className={styles.offerHeader}>
                                <span className={styles.offerTitleAccent}>
                                  {parts[0]}
                                </span>{" "}
                                {normalText}
                              </p>
                            )
                          })()}
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <div className={styles.cardsContainer}>
                          <Grid container justify="center" spacing={2}>
                            {document.papers.map((paper: OffersPaper) => {
                              return (
                                <Grid
                                  item
                                  xs={6}
                                  sm={2}
                                  className={styles.maxGridHeight}
                                  key={paper.name}
                                >
                                  <div className={styles.offerPaperCard}>
                                    <a
                                      target="_blank"
                                      key={paper.path}
                                      href={paper.path}
                                    >
                                      <DocumentIcon />
                                    </a>
                                    <p>{paper.name}</p>
                                  </div>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  )
                })}
              </div>
            </div>
          </div>
          <footer className={styles.footer}>
            <div className={styles.footerContainer}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography className={styles.copyrightsLeft}>
                    Zapoznaj się z&nbsp;naszą
                    <a
                      target="_blank"
                      href="/Polityka_prywatnosci_Polenergia_Dystrybucja.pdf"
                      className={styles.link}
                    >
                      {" "}
                      polityką prywatności.
                    </a>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={styles.copyrightsRight}>
                    Masz pytania? <span className={styles.bold}>Zadzwoń</span>{" "}
                    <span className={styles.phone}>
                      <a href="tel: +48 699 606 707">+48 699 606 707</a>
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </footer>
        </div>
      )}
    </>
  )
}

export default Footer
