import React from "react"
import GwarancjaCenyImage from "../../images/gwarancja-ceny"
import { CennikCena } from "../cenniki"
import theme from "../../../styles/theme"
import { FormTypes } from "./formularz"
import {
  EDocumentType,
  EOfferType,
  EAddressType,
  ECorrespondenceAddressType,
  EStatementType,
  EPartnerAddressType,
  EOwnershipType,
  EOwnershipSourceType,
  EEnergyDestinationType,
  EJdgEnergyDestinationType,
  EEInvoiceType,
  EEInvoiceAddressType,
  EAggreeType,
  EOfferDocumentsAnhor,
  EOfferDuration,
} from "../../../models/OffersEnums"

export const fileField: FormTypes = {
  name: "file",
  conditionalPlaceholder: file =>
    file ? (
      <p>
        Plik{" "}
        <span style={{ fontWeight: theme.typography.fontWeightBold }}>
          {file.file.name}{" "}
        </span>
        został załączony
      </p>
    ) : (
      <p>
        Skan lub zdjęcie aktualnej umowy z&nbsp;Twoim obecnym dostawca energii
        elektrycznej
      </p>
    ),
}

export const newCustomerOfferData = [
  {
    header: "Ceny",
    Bg: GwarancjaCenyImage,
    advAnchor: "cena",
    advHeader: "Zalety gwarancji ceny:",
    PricesComponent: CennikCena,
    docsHeader: "Dokumenty Gwarancja Ceny",
    customer: {
      commercialClient: false,
      foreignClient: false,
      companyName: "",
      taxNumber: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      phonePrefix: "",
      PzoZot: "",
      meterNumber: "",
      meterQuantity: "",
      phoneAgree: false,
      emailAgree: false,
      PPE: {
        street: "",
        nr1: "",
        nr2: "",
        post: "",
        city: "",
      },
    },
    offer: {
      offerType: EOfferType.STANDARD,
      offerDuration: EOfferDuration.TWO,
      isLong: true,
      offers: [
        {
          type: EOfferType.STANDARD,
          title: "Taryfa Sprzedawcy",
          subtitle: "Oferta Standardowa - Umowa na czas nieokreślony",
          description: (
            <span>
              Oferta Standardowa - Umowa na czas nieokreślony. Wysokość stawek
              zmiennych za energię elektryczną zgodna z&nbsp;decyzją Prezesa
              URE.
            </span>
          ),
          anhor: EOfferDocumentsAnhor.STANDARD,
          img: "standard",
          document: {
            name: "Taryfa",
            path: "/Taryfa_PolenergiaDystrybucja.pdf",
          },
        },
      ],
    },
    documents: [
      {
        id: EOfferDocumentsAnhor.STANDARD,
        header: "Ważne Dokumenty",
        papers: [
          {
            name: "Umowa - konsument",
            path: "/UMOWA-siec_POLD-Komplex-konsument.pdf",
          },
          {
            name: "Umowa - działalność",
            path: "/UMOWA-siec_POLD-Komplex-dzialalnosc.pdf",
          },
          {
            name: "OWU",
            path: "/OWU-siec_POLD_kompleks_G-konsum_dzialalnosc.pdf",
          },
          {
            name: "Wybór oferty - konsument",
            path: "/Wybor_oferty_zgody_RODO_klauzula-siec_POLD_G_konsument.pdf",
          },
          {
            name: "Wybór oferty - działalność",
            path:
              "/Wybor_oferty_zgody_RODO_klauzula-siec_POLD_G_dzialalnosc.pdf",
          },
        ],
      },
      {
        id: EOfferDocumentsAnhor.STANDARD,
        header: "",
        papers: [
          {
            name: "Zbiór praw konsumenta",
            path:
              "/Zbior_praw_konsumenta_energii_elektrycznej__wersja_kolorowa_.pdf",
          },
          {
            name: "Odstąpienie od umowy",
            path: "/Odstąpienie_od_umowy.pdf",
          },
        ],
      },
    ],
    offerData: {
      document: {
        documentType: EDocumentType.PESEL,
        documentNumber: "",
      },
      addressType: EAddressType.NONE,
      address: {
        street: "",
        city: "",
      },
      correspondenceAddressType: ECorrespondenceAddressType.NONE,
      correspondenceAddress: {
        street: "",
        city: "",
      },
      statementType: EStatementType.SELF,
      partnerData: {
        name: "",
        document: {
          documentType: EDocumentType.PESEL,
          documentNumber: "",
        },
      },
      partnerAddressType: EPartnerAddressType.NONE,
      partnerAddress: {
        street: "",
        city: "",
      },
      ownershipType: EOwnershipType.YES,
      ownershipSourceType: EOwnershipSourceType.OTHER,
      ownershipSource: "Protokół zdawczo-odbiorczy",
      energyDestinationType: EEnergyDestinationType.FLAT,
      energyDestination: "",
      jdgEnergyDestinationType: EJdgEnergyDestinationType.RENTING,
      jdgEnergyDestination: "",
      eInvoiceType: EEInvoiceType.YES,
      eInvoiceAddressType: EEInvoiceAddressType.NONE,
      eInvoiceAddress: "",
      aggreeType: EAggreeType.YES_CLIENT,
    },
    fileField: fileField,
  },
]
