import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { Formularz, FormProps } from "../sections/formularz"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      maxHeight: 0,
      padding: 0,
      textAlign: "right",
      "& button": {
        top: "-20px",
        right: "-20px",
        position: "relative",
        padding: "4px",
      },
    },
    dialogContent: {
      padding: 0,
    },
  })
)

interface Props extends FormProps {
  show: boolean
  toggleModal: () => void
  id?: Number
}

export const FormModal: React.FC<Props> = ({
  toggleModal,
  show,
  getCalendarValue,
  onSucces,
  onError,
  id,
  avoidFileAdding,
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const closeModal = () => {
    toggleModal()
  }

  return (
    <Dialog
      aria-describedby="form-modal"
      open={show}
      scroll={smallScreen || id ? "body" : "paper"}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle className={classes.title}>
        <IconButton aria-label="close" onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Formularz
          getCalendarValue={() => getCalendarValue()}
          onSucces={now => onSucces(now)}
          onError={onError}
          id={id}
          avoidFileAdding={avoidFileAdding}
        />
      </DialogContent>
    </Dialog>
  )
}
