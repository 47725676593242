import React, { useRef, useEffect, useState } from "react"
import { Formik, Form, Field } from "formik"
import { useTheme } from "@material-ui/styles"
import {
  makeStyles,
  LinearProgress,
  Grid,
  Theme,
  Button,
  Portal,
  useMediaQuery,
} from "@material-ui/core"
import { CustomInput } from "../Input"
import StyledButton from "../Button"
import { ToggleButtons } from "../ToggleButtons"
import { CustomCheckbox } from "../CheckBox"
import {
  contactFormFields,
  inputFields,
  consentFileds,
  FormTypes,
  placeholdersDict,
  FormID,
} from "./static-data/formularz"
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined"
import { DateRange } from "../meetingModal/datePicker/hourPicker/hourPicker"
import { submitData } from "../../api/form"
import { greyColor } from "../../styles/constants"
import { Transition, animated } from "react-spring"

const AnimatedGrid = animated(Grid)
import ReCAPTCHA from "react-google-recaptcha"
import { trackCustomEvent } from "../../utils/analytics"
import { EFormTypes } from "../../models/OffersEnums"
import { ServerErrorModalContent } from "../formComponents/ServerErrorModalContent"
import { ForeignClientModalContent } from "../formComponents/ForeignClientModalContent"

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    "& h1": {
      alignSelf: "flex-start",
      margin: 0,
      fontSize: "31px",
      letterSpacing: "1.05px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& h2": {
      alignSelf: "flex-start",
      color: greyColor,
      margin: 0,
      fontSize: "14px",
      letterSpacing: "0.10px",
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  recaptcha: {
    position: "fixed",
    top: "25%",
    left: "50%",
    zIndex: 1600,
    [theme.breakpoints.down("sm")]: {
      "& .grecaptcha-badge": {
        bottom: "5px !important",
      },
    },
  },
  formComponent: {
    width: "100%",
  },
  gridPaddingLeft: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "8px",
    },
    paddingLeft: "12px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      marginBottom: "4px",
    },
  },
  gridPaddingRight: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "8px",
    },
    paddingRight: "12px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      marginBottom: "4px",
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#465B71",
    marginTop: "12px",
    "& p": {
      fontSize: "11px",
      letterSpacing: "0.3px",
      margin: 0,
    },
    "& > *": {
      display: "flex",
      alignItems: "center",
      margin: "16px 0 0",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  label: {
    alignItems: "flex-start",
    "& p": {
      marginLeft: "12px",
    },
  },
  fileAdding: {
    margin: "28px 0 0",
    "& p": {
      marginLeft: "16px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "16px 0 0",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "16px",
      "& p": {
        margin: "8px 0 0",
        textAlign: "center",
      },
    },
  },
  fileButton: {
    minWidth: "126px",
    height: "37px",
    borderRadius: "7px",
    border: "2.5px solid #FFFFFF",
    padding: 0,
    color: "white",
    fontSize: "12px",
    letterSpacing: "0.22px",
    fontWeight: theme.typography.fontWeightBold,

    [theme.breakpoints.down("sm")]: {
      minWidth: "206px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "170px",
    },
  },
  buttonsContainer: {
    display: "flex",
    margin: "30px auto",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      margin: "20px auto",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "20px auto",
      flexDirection: "column",
    },
    "& button": {
      "&:first-child": {
        minWidth: "201px",
        marginRight: "20px",
        [theme.breakpoints.down("xs")]: {
          minWidth: "85%",
          margin: "10px 0",
        },
      },
      "&:last-child": {
        minWidth: "268px",
        [theme.breakpoints.down("xs")]: {
          minWidth: "85%",
          margin: "10px 0",
        },
      },
      height: "46px",
      lineHeight: "normal",
    },
  },
  consentRequired: {
    color: theme.palette.error.main,
  },
  fileSizeError: {
    color: theme.palette.error.main,
    display: "block",
  },
  formValidationError: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    padding: "0 12px",
    borderRadius: "24px",
    backgroundColor: theme.palette.error.main,
    color: "white",
    width: "78%",
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      fontSize: "10.5px",
    },
    "& p": {
      margin: 0,
      marginLeft: "4px",
    },
  },
  footer: {
    margin: 0,
    color: "#465B71",
    textAlign: "center",
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.main,
      transition: "color 0.15s ease-in-out",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  },
  privacy: {
    margin: "12px 0 0",
    color: "black",
    textAlign: "center",
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      margin: "16px 0 4px",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
      transition: "color 0.15s ease-in-out",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  },
}))

export interface FormProps {
  getCalendarValue: () => Promise<DateRange>
  onSucces: (now: boolean) => void
  onError: (err?: string) => void
  id?: Number
  avoidFileAdding?: boolean
}

export const Formularz: React.FC<FormProps> = ({
  getCalendarValue,
  onSucces,
  onError,
  id,
  avoidFileAdding,
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const extraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"))

  const recaptchaRef = useRef<any>()

  let FieldsToRender = id ? inputFields.slice(0, 6) : inputFields
  let conditionalMargin = id ? "20px" : "0px"

  const [wrongFileSize, setWrongFileSize] = useState(false)
  const getFile = (e, setFieldValue) => {
    e.preventDefault()
    const file = e.target.files[0]
    if (file) {
      const fileSizeMB = file.size / 1000
      if (fileSizeMB < 10000) {
        setWrongFileSize(false)
        setFieldValue("file", file)
      } else {
        setWrongFileSize(true)
        setFieldValue("file", null)
      }
    }
  }

  const validateField = (value, field: FormTypes) => {
    if (field.required && !value) {
      return field.errorEmpty
    }

    if (value && field.validation && field.validation(value)) {
      return field.errorMsg
    }
  }

  useEffect(() => {
    trackCustomEvent({
      category: "Wysłanie formularza",
      action: `Użytkownik wyświetlił formularz`,
    })
  }, [])

  const submit = async (
    values,
    errors,
    setSubmitting,
    resetForm,
    now: boolean = true
  ) => {
    trackCustomEvent({
      category: "Wysłanie formularza",
      action: `Użytkownik wcisnął ${now ? "zadzwoń teraz" : "umawiam rozmowę"}`,
    })
    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(key => {
        trackCustomEvent({
          category: "Błąd walidacji formularza",
          action: "Błędnie wypełnione pole",
          label: `${placeholdersDict[key]} - ${errors[key]}`,
        })
      })

      return
    }

    let dates

    if (!now) {
      try {
        dates = await getCalendarValue()
      } catch {
        setSubmitting(false)
        return
      }
    }

    const captcha = await recaptchaRef.current.executeAsync()

    if (!captcha) {
      onError("Nie została wykonana autoryzacja z użyciem reCaptcha")
      return
    }

    trackCustomEvent({
      category: "Wysłanie formularza",
      action: `Użytkownik poprawnie zgłosił prośbę o kontakt ${now ? "teraz" : "we wskazanym terminie"
        }`,
    })

    try {
      await submitData({
        captcha,
        formValue: { ...values },
        dates,
        useContactForm: avoidFileAdding
      })
      onSucces(now)
      trackCustomEvent({
        category: "Wysłanie formularza",
        action: "Zgłoszenie zostało przyjęte poprawnie",
      })
      resetForm()
    } catch (e) {
      trackError(e)
      onError()
    } finally {
      setSubmitting(false)
      recaptchaRef.current.reset()
    }
  }

  const onClick = () => {
    if (!smallScreen) return

    const [el] = document.getElementsByClassName("MuiDialog-scrollBody")

      ; (el as any).style.overflowY = "hidden"

    setTimeout(() => {
      ; (el as any).style.overflowY = "auto"
    }, 300)
  }

  const trackError = e => {
    if (e) {
      switch (e.status) {
        case 400:
          Object.keys(e.data).forEach(key => {
            trackCustomEvent({
              category: "Błąd serwera",
              action: "Niepoprawna walidacja danych",
              label: `${key} - ${e.data[key].join(", ")}`,
            })
          })
          return
        default:
          trackCustomEvent({
            category: "Błąd serwera",
            action: `Kod błędu serwera - ${e.status}`,
          })
          return
      }
    }

    trackCustomEvent({
      category: "Błąd serwera",
      action: ``,
    })
  }

  return (
    <div className={classes.formContainer} id={FormID}>
      <Portal>
        <ReCAPTCHA
          className={classes.recaptcha}
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.GATSBY_RECAPTCHA_KEY}
        />
      </Portal>
      {!id ? (
        <>
          <h1>
            <span style={{ color: theme.palette.secondary.main }}>
              Zielona Energia{" "}
            </span>
            Dla Mobilności
          </h1>
          <h2>
            Skontaktuj się z&nbsp;nami lub zamów kontakt w&nbsp;dogodnym dla
            Ciebie terminie.
          </h2>
        </>
      ) : id == EFormTypes.PESEL ? (
        <ForeignClientModalContent />
      ) : (
        <ServerErrorModalContent />
      )}

      <Formik
        initialValues={contactFormFields.slice(0).reduce(
          (acc, field) => {
            acc[field.name] = ""
            return acc
          },
          {
            commercialClient: false,
          }
        )}
        onSubmit={() => { }}
      >
        {({
          isSubmitting,
          values,
          isValid,
          setFieldValue,
          validateForm,
          submitForm,
          setSubmitting,
          resetForm,
        }) => (
          <>
            <Form className={classes.formComponent}>
              {!id && (
                <Field name="commercialClient">
                  {({ field }) => (
                    <ToggleButtons
                      isCommercial={field.value}
                      handleIsCommercial={isCommercial =>
                        isCommercial !== null &&
                        setFieldValue("commercialClient", isCommercial)
                      }
                    />
                  )}
                </Field>
              )}

              <Grid
                container
                alignItems="center"
                style={{ marginTop: conditionalMargin }}
              >
                {FieldsToRender.map((fieldConfig, i) => {
                  const isVisible =
                    (fieldConfig.simpleContactFormValue || id != EFormTypes.PESEL)
                    && (!fieldConfig.conditionalHidden
                      || fieldConfig.conditionalHidden(values))
                  return (
                    <Transition
                      items={isVisible}
                      from={{
                        ...(!fieldConfig.conditionalHidden
                          ? {}
                          : {
                            opacity: 0,
                            height: "0px",
                          }),
                      }}
                      enter={{ height: "59px", opacity: 1 }}
                      leave={{ height: "0px", opacity: 0, marginBottom: 0 }}
                      key={i}
                    >
                      {(values, visible) =>
                        visible && (
                          <AnimatedGrid
                            item
                            md={fieldConfig.width || 6}
                            xs={12}
                            style={values}
                            className={
                              fieldConfig.name !== "currentSupplier"
                                ? i % 2
                                  ? classes.gridPaddingLeft
                                  : classes.gridPaddingRight
                                : null
                            }
                          >
                            <Field
                              name={fieldConfig.name}
                              validate={value =>
                                validateField(value, fieldConfig)
                              }
                            >
                              {({ field, meta: { error, touched } }) => {
                                const showError = Boolean(error && touched)
                                return (
                                  <CustomInput
                                    field={field}
                                    metaError={error}
                                    showError={showError}
                                    placeholder={fieldConfig.placeholder}
                                    onClick={onClick}
                                  />
                                )
                              }}
                            </Field>
                          </AnimatedGrid>
                        )
                      }
                    </Transition>
                  )
                })}
              </Grid>

              {isSubmitting && <LinearProgress style={{ margin: "8px 0 0" }} />}

              <div className={classes.sectionContainer}>
                {consentFileds.map(consent => (
                  <label className={classes.label} key={consent.name}>
                    <Field
                      name={consent.name}
                      validate={value => validateField(value, consent)}
                    >
                      {({ field, meta: { error, touched } }) => {
                        const showError = Boolean(error && touched)
                        return consent.simpleContactFormValue != true ? null : (
                          <CustomCheckbox
                            field={field}
                            consent={consent}
                            error={error}
                            showError={showError}
                          />
                        )
                      }}
                    </Field>
                  </label>
                ))}

                {!avoidFileAdding && (<div className={classes.fileAdding}>
                  <div>
                    <input
                      accept="image/*,.pdf"
                      id="button-file"
                      type="file"
                      style={{ display: "none" }}
                      onChange={event => getFile(event, setFieldValue)}
                    />
                    <label htmlFor="button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.fileButton}
                        component="span"
                      >
                        {values["file"] ? "Zmień plik" : "Załącz plik"}
                      </Button>
                    </label>
                  </div>
                  <div>
                    {values["file"] ? (
                      <p>
                        Plik{" "}
                        <span
                          style={{
                            fontWeight: theme.typography.fontWeightBold,
                          }}
                        >
                          {values["file"].name}{" "}
                        </span>
                        został załączony
                      </p>
                    ) : (
                      <p>
                        Skan lub zdjęcie obecnej faktury lub umowy z&nbsp;Twoim
                        obecnym sprzedawcą energii przyspieszy przygotowanie
                        oferty dla Ciebie.
                      </p>
                    )}
                    <Transition
                      items={wrongFileSize}
                      from={{
                        opacity: 0,
                        height: "0px",
                        marginTop: "0px",
                      }}
                      enter={{ opacity: 1, height: "15px", marginTop: "2px" }}
                      leave={{ opacity: 0, height: "0px", marginTop: "0px" }}
                    >
                      {(values, visible) => {
                        return (
                          visible && (
                            <animated.p
                              style={values}
                              className={classes.fileSizeError}
                            >
                              Maksymalny rozmiar pliku 10MB
                            </animated.p>
                          )
                        )
                      }}
                    </Transition>
                  </div>
                </div>)}

              </div>
              {smallScreen && (
                <Transition
                  items={!isValid}
                  from={{
                    opacity: 0,
                    height: "0px",
                    marginTop: "0px",
                  }}
                  enter={{
                    opacity: 1,
                    height: extraSmallScreen ? "48px" : "40px",
                    marginTop: extraSmallScreen ? "30px" : "20px",
                  }}
                  leave={{ opacity: 0, height: "0px", marginTop: "0px" }}
                >
                  {(values, visible) => {
                    return (
                      visible && (
                        <animated.div
                          style={values}
                          className={classes.formValidationError}
                        >
                          <EmojiObjectsOutlinedIcon />
                          <p>
                            Aby umówić rozmowę wypełnij wszystkie wymagane pola
                          </p>
                        </animated.div>
                      )
                    )
                  }}
                </Transition>
              )}
              <div className={classes.buttonsContainer}>
                <StyledButton
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={async () => {
                    await submitForm()
                    submit(
                      values,
                      await validateForm(),
                      setSubmitting,
                      resetForm,
                      false
                    )
                    setWrongFileSize(false)
                  }}
                >
                  Umawiam rozmowę
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={async () => {
                    await submitForm()
                    submit(
                      values,
                      await validateForm(),
                      setSubmitting,
                      resetForm
                    )
                    setWrongFileSize(false)
                  }}
                >
                  Zadzwońcie do mnie teraz
                </StyledButton>
              </div>

              <p className={classes.footer}>
                <a href="tel:+48 699 606 707">+48 699 606 707 </a>{" "}
                <span style={{ fontSize: "11px" }}>
                  (Cena za połączenie zgodnie z&nbsp;taryfą Twojego Operatora)
                </span>
              </p>
              <p className={classes.footer}>
                Konsultanci są dla Ciebie dostępni: pon-pt 8:00-18:00<br /><a href="mailto:oferta@cc.polenergia.pl">oferta@cc.polenergia.pl</a>
              </p>
              <p className={classes.privacy}>
                Zapoznaj się z{" "}
                <a
                  target="_blank"
                  href="/Polityka_prywatności_Polenergia_Dystrybucja.pdf"
                >
                  polityką prywatności
                </a>
              </p>
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}
