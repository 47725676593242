import React, { Fragment } from "react"
import { makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import { useTheme } from "@material-ui/styles"
import { graphql, useStaticQuery } from "gatsby"
import { maxWidth, relativeContentPadding } from "../../styles/constants"
import theme from "../../styles/theme"
import { Doc, gwarancje, wspolneDokumenty } from "./static-data/gwarancje"
import GwarancjaRabatuWiatrakiImage from "../images/gwarancja-rabatu-wiatraki"
import StyledButton from "../Button"
import scrollTo from "gatsby-plugin-smoothscroll"
import { FormID } from "./static-data/formularz"
import Consumer from "../../context/Context"

const useStyles = makeStyles({
  mainContainer: {
    ...relativeContentPadding(),
    overflow: "hidden",
  },
  flexContainer: {
    ...maxWidth,
    display: "flex",
    padding: "60px 0",
    alignItems: "stretch",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      overflow: "visible",
    },
  },
  sectionContainer: {
    flex: "1",
    paddingRight: "30px",
    overflow: "hidden",
    "&:nth-child(2)": {
      paddingRight: 0,
      paddingLeft: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 !important",
      overflow: "visible",
      marginBottom: "40px",
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: "5px",
    },
  },
  img: {
    position: "absolute !important" as "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
  },
  topCard: {
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      margin: "0 -15px",
    },
  },
  innerCard: {
    padding: "30px 20px",
    zIndex: 1,
  },
  subSectionHeader: {
    margin: "30px 0 20px",
  },
  docsSection: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "& a": {
      marginBottom: "10px",
      textDecoration: "none",
      lineHeight: "10px",
    },
    "& img": {
      display: "block",
      margin: "0 auto",
      height: "50px",
    },
  },
  docsSectionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  docCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "150px",
    height: "120px",
    padding: "12px 20px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around",
      width: "100%",
      height: "142px",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      padding: "20px 10px",
    },
  },
  docTitle: {
    margin: 0,
    fontSize: "11.5px",
    lineHeight: "12px",
    height: "36px",
  },
  commonDocs: {
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      textAlign: "right",
    },
  },
  commonDocsHeader: {
    textAlign: "center",
  },
  commonDocsSection: {
    padding: "40px 100px",
    backgroundColor: theme.palette.grey[100],
    margin: "0 -100px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 -15px",
      padding: "20px 15px",
    },
  },
  doc: {
    padding: "10px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
      width: "50%",
    },
  },
  desktopSection: {
    ...maxWidth,
    marginTop: "32px",
    display: "flex",
    justifyContent: "space-between",
    "&> div": {
      width: "calc(50% - 20px)",
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      alignItems: "center",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "85%",
    width: "100%",
    "& h2": {
      fontWeight: theme.typography.fontWeightLight,
      margin: "0 0 8px",
      textAlign: "center",
    },
    "& p": {
      margin: "8px 16px 0",
    },
  },
  cardSecondHeader: {
    textAlign: "center",
    fontSize: "16px",
    "& span": {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
  },
  cardButton: {
    alignSelf: "center",
    padding: "0 10px",
    margin: "auto 0 2px",
    width: "35%",
    fontSize: "17px",
    minHeight: "48px",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: "0 0 14px",
      fontSize: "15px",
    },
  },
  infoImg: {
    height: "250px",
    borderRadius: "8px",
  },
})

const DocComponent: React.FC<{
  doc: Doc
}> = ({ doc }) => {
  const styles = useStyles()

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "Group 4.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <a target="_blank" key={doc.path} href={doc.path} className={styles.doc}>
      <Card className={styles.docCard}>
        <img src={data.file.publicURL} />
        <p className={styles.docTitle}>{doc.title}</p>
      </Card>
    </a>
  )
}

const GwarancjaRabatu: React.FC = () => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Consumer>
      {({ setShowForm }) => (
        <div className={styles.cardContainer}>
          <Card className={styles.card}>
            <h2>
              Cennik:{" "}
              <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                Gwarancja{" "}
              </span>
              <span
                style={{
                  fontWeight: theme.typography.fontWeightBold,
                  color: theme.palette.primary.main,
                }}
              >
                Rabatu{" "}
              </span>
            </h2>
            <p
              className={styles.cardSecondHeader}
              style={{
                margin: "0 0 12px",
              }}
            >
              <span>5%</span> rabatu na 2, 3, 5 lat lub <span>10%</span> rabatu
              na 5 lat
            </p>
            <GwarancjaRabatuWiatrakiImage className={styles.infoImg} />
            <p style={{ marginTop: "28px" }}>
              Zainteresowany bezpłatnym otrzymaniem oferty na{" "}
              <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                Gwarancję{" "}
              </span>
              <span
                style={{
                  color: theme.palette.secondary.main,
                  fontWeight: theme.typography.fontWeightBold,
                }}
              >
                Rabatu
              </span>
              ?
            </p>
            <p style={{ marginBottom: "28px" }}>
              Skontaktuj się z Nami poprzez formularz kontaktowy
            </p>
            <StyledButton
              className={styles.cardButton}
              color="primary"
              variant="contained"
              onClick={() =>
                smallScreen ? setShowForm(true) : scrollTo(`#${FormID}`)
              }
            >
              Kontakt
            </StyledButton>
          </Card>
        </div>
      )}
    </Consumer>
  )
}

export const GwarancjeSection: React.FC = () => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const generateByType = () => (
    <div className={styles.flexContainer}>
      {gwarancje.map(({ Bg, PricesComponent, ...gwarancja }) => (
        <div className={styles.sectionContainer} key={gwarancja.header}>
          <section>
            <Typography
              variant="h4"
              className={styles.subSectionHeader}
              id={gwarancja.advAnchor}
            >
              {gwarancja.advHeader}
            </Typography>
            <Typography>{gwarancja.advContent}</Typography>
          </section>
          {gwarancja.prices ? (
            <section>
              <Typography
                variant="h4"
                className={styles.subSectionHeader}
                style={{ alignSelf: "flex-start" }}
              >
                Cennik:
              </Typography>
              <PricesComponent prices={gwarancja.prices} />
            </section>
          ) : (
            <GwarancjaRabatu />
          )}

          <section>
            <Typography
              variant="h4"
              className={styles.subSectionHeader}
              style={{ textAlign: "center" }}
            >
              {gwarancja.docsHeader}
            </Typography>
            <div className={styles.docsSection}>
              {gwarancja.docs.map((doc, i) => (
                <DocComponent key={doc.path + i} doc={doc} />
              ))}
            </div>
          </section>
        </div>
      ))}
    </div>
  )

  const generateBySections = () => (
    <>
      <section className={styles.desktopSection}>
        {gwarancje.map(({ Bg, PricesComponent, ...gwarancja }) => (
          <div key={gwarancja.advHeader}>
            <Typography
              variant="h4"
              className={styles.subSectionHeader}
              id={gwarancja.advAnchor}
            >
              {gwarancja.advHeader}
            </Typography>
            <Typography>{gwarancja.advContent}</Typography>
          </div>
        ))}
      </section>

      <section className={styles.desktopSection}>
        {gwarancje.map(({ Bg, PricesComponent, ...gwarancja }) => (
          <Fragment key={gwarancja.advHeader}>
            {gwarancja.prices ? (
              <div
                className={styles.cardContainer}
                key={gwarancja.header + "_cennik"}
              >
                <Typography variant="h4" className={styles.subSectionHeader}>
                  Cennik:
                </Typography>
                <PricesComponent prices={gwarancja.prices} />
              </div>
            ) : (
              <GwarancjaRabatu />
            )}
          </Fragment>
        ))}
      </section>

      <section className={styles.desktopSection}>
        {gwarancje.map(({ Bg, PricesComponent, ...gwarancja }) => (
          <div
            key={gwarancja.docsHeader}
            className={styles.docsSectionContainer}
          >
            <Typography
              variant="h4"
              className={styles.subSectionHeader}
              style={{ textAlign: "center" }}
            >
              {gwarancja.docsHeader}
            </Typography>
            <div className={styles.docsSection}>
              {gwarancja.docs.map((doc, i) => (
                <DocComponent key={doc.path + i} doc={doc} />
              ))}
            </div>
          </div>
        ))}
      </section>
    </>
  )

  return (
    <div className={styles.mainContainer}>
      {smallScreen ? generateByType() : generateBySections()}
      <section className={styles.commonDocsSection}>
        <Typography
          variant="h4"
          className={`${styles.subSectionHeader} ${styles.commonDocsHeader}`}
        >
          Dokumenty wspólne dla obu ofert
        </Typography>
        <div className={`${styles.docsSection} ${styles.commonDocs}`}>
          {wspolneDokumenty.map(doc => (
            <DocComponent key={doc.path} doc={doc} />
          ))}
        </div>
      </section>
    </div>
  )
}
