import React from "react"
import LandingImage from "../components/images/landing-bg"
import Header from "../components/header"
import { createStyles, makeStyles, Theme } from "@material-ui/core"
import { contentPadding, relativeContentPadding } from "../styles/constants"

import Card from "@material-ui/core/Card"
import { BaseProps } from "../utils/baseProps"

export interface ContainerWithFixedBgProps extends BaseProps {
  ImageComponent: React.FC<BaseProps>
  fixed?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      ...relativeContentPadding(),
      [theme.breakpoints.down("lg")]: {
        paddingLeft: "95px",
        paddingRight: "95px",
      },
    },
    bgImage: {
      position: "absolute !important" as "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
    },
    bgImageFixed: {
      position: "fixed !important" as "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
    },
    noPadding: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  })
)

export const ContainerWithFixedBg: React.FC<ContainerWithFixedBgProps> = ({
  children,
  ImageComponent,
  className,
  fixed,
}) => {
  const styles = useStyles()

  return (
    <div className={`${styles.container} ${styles[className]}`}>
      {children}
      <ImageComponent
        className={fixed ? styles.bgImageFixed : styles.bgImage}
      />
    </div>
  )
}
