import React, { useState, useEffect } from "react"
import { makeStyles, Theme } from "@material-ui/core"
import { Month } from "./month/month"
import { Calendar } from "./calendar/calendar"
import { HourPicker, DateRange } from "./hourPicker/hourPicker"
import {
  addMonths,
  endOfMonth,
  isAfter,
  isSameDay,
  isBefore,
  set,
  addDays,
  getDay,
} from "date-fns"
import {
  getWorkingHours,
  periodLength,
} from "../../sections/static-data/formularz"

const useStyles = makeStyles((theme: Theme) => ({
  monthSelect: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "16px",
    [theme.breakpoints.down("xs")]: {
      margin: "16px 0",
    },
  },
  container: {
    minHeight: "440px",
    display: "flex",
    flexDirection: "row",
    margin: "16px 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  calendar: {
    display: "flex",
    flexGrow: 1,
    minWidth: "345px",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0 21px 0 8px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 24px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
      margin: 0,
    },
  },
  hourPicker: {
    margin: 0,
    marginLeft: "21px",
    [theme.breakpoints.up("md")]: {
      width: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 24px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
}))

interface Props {
  setCalendarData: ({}) => void
}

export const getCurrentDay = () => {
  const date = new Date()
  const workingHours = getWorkingHours(getDay(date))
  return workingHours &&
    isBefore(
      date,
      set(date, {
        hours: workingHours.endHour - 1,
        minutes: periodLength,
      })
    )
    ? date
    : set(addDays(date, 1), { hours: 0 })
}

export const DatePicker: React.FC<Props> = ({ setCalendarData }) => {
  const classes = useStyles()
  const currentDay = getCurrentDay()
  const [selectedDate, setSelectedDate] = useState(currentDay)
  const [hoursPeriod, setHoursPeriod] = useState<DateRange>({
    start: new Date(),
    end: new Date(),
  })
  const monthPeriodLength = 2
  const maxDate = endOfMonth(addMonths(currentDay, monthPeriodLength))
  let correctDate: boolean = true

  useEffect(() => {
    correctDate =
      isAfter(selectedDate, currentDay) || isSameDay(selectedDate, currentDay)
    setCalendarData(hoursPeriod)
  }, [selectedDate])

  const setHours = (range: DateRange) => {
    setHoursPeriod(range)
    setCalendarData(range)
  }

  return (
    <>
      <div className={classes.monthSelect}>
        <Month date={selectedDate} setDate={setSelectedDate} limit={maxDate} />
      </div>
      <div className={classes.container}>
        {correctDate && (
          <>
            <div className={classes.calendar}>
              <Calendar
                date={selectedDate}
                setDate={setSelectedDate}
                limit={maxDate}
              />
            </div>
            <div className={classes.hourPicker}>
              <HourPicker date={selectedDate} setHoursPeriod={setHours} />
            </div>
          </>
        )}
      </div>
    </>
  )
}
