import React from "react"
import LandingImage from "../images/landing-bg"
import Header from "../header"
import { makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core"
import Img from "gatsby-image"

import Card from "@material-ui/core/Card"
import { useTheme, createStyles } from "@material-ui/styles"
import { ContainerWithFixedBg } from "../containerWithFixedBg"
import LandingMask from "../images/landing-mask"
import BakBg from "../images/bak-bg"
import { useStaticQuery, graphql } from "gatsby"
import { maxWidth } from "../../styles/constants"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionContainer: {
      paddingTop: "60px",
      paddingBottom: "60px",
    },
    card: {
      ...maxWidth,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 70px",
      "& h1": {
        fontSize: "1.6em",
        margin: "0 0 10px",
      },
      "& p": {
        fontSize: "15px",
        margin: 0,
      },

      [theme.breakpoints.down("md")]: {
        padding: "10px 20px",
        "& h1": {
          fontSize: "24px",
          marginBottom: "4px",
          lineHeight: "30px",
        },
        "& p": {
          fontSize: "14px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "20px",
        position: "relative",
        "& h1": {
          fontSize: "28px",
          marginBottom: "20px",
          lineHeight: "30px",
        },
      },
    },
    img: {
      width: "1400px",
      height: "100%",
      margin: "-10px -70px -10px 16px",
      [theme.breakpoints.down("md")]: {
        margin: "-10px -60px -10px 8px",
        width: "1900px",
      },
      [theme.breakpoints.down("sm")]: {
        alignSelf: "center",
        margin: "20px 0 -20px",
        width: "calc(100% + 44px)",
        height: "auto",
      },
    },
  })
)

export const DarmowyBakSection: React.FC = () => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const images = useStaticQuery(graphql`
    {
      desktop: file(relativePath: { eq: "darmowy-bak-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobile: file(relativePath: { eq: "darmowy-bak-img-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContainerWithFixedBg
      ImageComponent={BakBg}
      className={styles.sectionContainer}
    >
      <Card className={styles.card}>
        <div>
          <h1>
            <span style={{ fontWeight: theme.typography.fontWeightLight }}>
              Promocja{" "}
            </span>
            Darmowy Pakiet{" "}
            <span style={{ color: theme.palette.secondary.main }}>
              Zielonej Energii{" "}
            </span>
            dla Klientów Volkswagen Group Polska
          </h1>
          <Typography>
            Dla pierwszych 500 Klientów, którzy posiadają lub planują zakup
            samochodu elektrycznego lub hybrydowego plug-in i&nbsp;skorzystają
            z&nbsp;naszej Oferty Gwarancja Ceny lub Gwarancja Rabatu, oferujemy{" "}
            <span
              style={{
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              Darmowy Pakiet{" "}
            </span>
            <span
              style={{
                color: theme.palette.secondary.main,
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              Zielonej Energii
            </span>
            . Sprawdź szczegóły promocji w&nbsp;Regulaminie Promocji lub zadzwoń
            do naszego konsultanta.
          </Typography>
        </div>
        <Img
          fluid={
            (smallScreen ? images.mobile : images.desktop).childImageSharp.fluid
          }
          className={styles.img}
        />
      </Card>
    </ContainerWithFixedBg>
  )
}
