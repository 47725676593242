import React from "react"
import theme from "../../../styles/theme"

export interface Info {
  volkswagen: Element
  polenergia: Element
  info: Element
}

export const informationContent = (type: string) => {
  const { volkswagen, polenergia, info } = information
  switch (type) {
    case "vw":
      return volkswagen
    case "polenergia":
      return polenergia
    default:
      return info
  }
}

export const information = {
  volkswagen: (
    <>
      Grupa Volkswagen stawia na napędy alternatywne względem spalinowych,
      zmierzając w&nbsp;kierunku redukcji swojego śladu węglowego do zera. Auta
      elektryczne to najbliższa przyszłość motoryzacji i&nbsp;kierunek, który
      jednoznacznie obrały marki należące do Grupy, jednocześnie dbając
      o&nbsp;rozwój swojego portfolio w&nbsp;kierunku hybryd typu plug-in
      o&nbsp;wysokim zasięgu elektrycznym. Koncern jest więc na drodze do
      oferowania zeroemisyjnej mobilności zobowiązując się do przestrzegania
      postanowień Porozumienia Paryskiego zamierzając do 2025 roku zmniejszyć
      emisję CO2 swoich samochodów w&nbsp;całym cyklu ich życia o&nbsp;30
      procent w&nbsp;porównaniu z&nbsp;rokiem 2015.
      <br />
      Celem jest aby do roku 2050 stać się przedsiębiorstwem, którego
      działalność jest neutralna pod względem ogólnego bilansu dwutlenku węgla.
      Wysiłki służące ochronie środowiska dotyczą całego cyklu życia produktu –
      od projektu, przez produkcję i&nbsp;użytkowanie, po recykling samochodów.
      ID.3 to pierwszy samochód marki Volkswagen produkowany w&nbsp;sposób
      neutralny dla środowiska pod kątem emisji CO2, uwzględniając cały łańcuch
      dostaw.
    </>
  ),
  polenergia: (
    <>
      Polenergia jest największą niezależną polską, pionowo zintegrowaną grupą
      energetyczną. Stojąc u&nbsp;progu ogromnej transformacji rynku energii
      Polski, ambicją i&nbsp;misją Grupy jest wspieranie wysiłków związanych
      z&nbsp;rozwojem gospodarki niskoemisyjnej, czystych i&nbsp;odnawialnych
      źródeł energii, a&nbsp;także dążenia UE do osiągnięcia neutralności
      klimatycznej.  Wierzymy, że Polska może odegrać ogromną rolę
      w&nbsp;transformacji energetycznej Europy Środkowej. 
      <br />
      Polenergia w&nbsp;kolejnych latach przewiduje dalszy dynamiczny rozwój
      projektów morskich i&nbsp;lądowych farm wiatrowych, wielkopowierzchniowych
      farm fotowoltaicznych oraz rozwój zaawansowanych technologii gazowych,
      będących podstawą do transformacji wodorowej, do której Grupa
      w&nbsp;najbliższych latach chce być w&nbsp;pełni przygotowana.  Będąc
      aktywnym uczestnikiem rynku energii od początku – fazy rozwoju
      i&nbsp;budowy projektów przez produkcję do  dostarczania energii Klientom
      biznesowym i&nbsp;indywidualnym, czujemy się odpowiedzialni za każdy
      aspekt ochrony środowiska i&nbsp;zgodność z&nbsp;wymogami bezpieczeństwa,
      współpracę z  lokalnymi społecznościami i&nbsp;naszymi Partnerami
      biznesowymi. Jako Partner i&nbsp;Sygnatariusz Standardu Etycznego UN
      Global Compact w&nbsp;naszych działaniach biznesowych kierujemy się
      odpowiedzialnością społeczną za obecne i&nbsp;przyszłe pokolenia
      i&nbsp;najwyższymi etycznymi zasadami.
    </>
  ),
  info: (
    <>
      Elektromobilność to obszar, na którym Volkswagen Group Polska i&nbsp;Grupa
      Polenergia podjęły współpracę, dzięki czemu już dziś możesz skorzystać
      z&nbsp;oferty na{" "}
      <span style={{ color: theme.palette.primary.main }}>
        Zieloną Energię dla Twojego domu i&nbsp;samochodu
      </span>
      . Cel jaki nam przyświeca to zapewnienie{" "}
      <span style={{ color: theme.palette.primary.main }}>
        Zielonej Energii{" "}
      </span>
      dla elektromobilności, co w&nbsp;efekcie pozwali skutecznie obniżyć ślad
      węglowy samochodu elektrycznego nie tylko na etapie produkcji, ale również
      w&nbsp;trakcie jego eksploatacji. Sprawdź jakie działania podejmują nasze
      grupy w&nbsp;trosce o&nbsp;środowisko i&nbsp;dołącz do grona osób
      odpowiedzialnych społecznie!
    </>
  ),
}
