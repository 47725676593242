// If you don't want to use TypeScript you can delete this file!
import React from "react"

import SEO from "../components/seo"
import { MainSection } from "../components/sections/main-section"
import { DarmowyBakSection } from "../components/sections/darmowy-bak"
import { GwarancjeSection } from "../components/sections/gwarancje"
import { InformacjaSection } from "../components/sections/informacja"
import Footer from "../components/Footer"
import { ELandingPage } from "../models/OffersEnums"
import { OffMessage } from "../components/off-message/off-message"

const offMessage: string = "Zapraszamy już wkrótce!"

const UsingTypescript: React.FC = () => {
  if (offMessage) {
    return <OffMessage message={offMessage} />
  }

  return (
    <>
      <SEO title={""} />
      <MainSection />
      <DarmowyBakSection />
      <GwarancjeSection />
      <InformacjaSection />
      <Footer landingPage={ELandingPage.EMOBILITY} />
    </>
  )
}

export default UsingTypescript
