// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { Slider, withTheme, withStyles, Theme } from "@material-ui/core"

export const StyledSlider = withStyles((theme: Theme) => ({
  root: {
    margin: "10px 15px",
    boxSizing: "border-box",
    maxWidth: "calc(100% - 30px)",
    padding: "13px 0",
    transition: "opacity 0.3s ease-in-out",
    "&.Mui-disabled": {
      opacity: 0.5,
    },
    "& .MuiSlider-rail": {
      width: "calc(100% + 30px) !important",
      height: "25px",
      top: "2.5px",
      marginLeft: "-15px",
      background: "#DBEFF0",
      borderRadius: "20px",
    },
    "& .MuiSlider-markLabel": {
      top: "2.5px",
      height: "25px",
      paddingLeft: "30px",
      paddingTop: "2px",
      paddingRight: "20px",
      marginLeft: "-15px",
      zIndex: 2,
      background: "transparent",
      transform: "none",
      "&:nth-last-child(2)": {
        paddingLeft: "20px",
        paddingRight: "40px",
        marginLeft: "5px",
        transform: "translateX(calc(-100% + 10px))",
      },
    },
    "& .MuiSlider-markLabelActive": {
      background: theme.palette.primary.main,
      borderRadius: "20px",
      color: "white",
    },
    "& .MuiSlider-mark": {
      width: "15px",
      height: "15px",
      marginTop: "-5.5px",
      marginLeft: "-10px",
      background: "grey",
      borderRadius: "100%",
      zIndex: "3",
      "&:nth-last-child(3)": {},
    },
    "& .MuiSlider-markActive": {
      background: "white",
    },
    "& .MuiSlider-thumb": {
      width: "15px",
      height: "15px",
      marginLeft: "-10px",
      marginTop: "-6px",
      background: "white",
    },
  },
}))(withTheme(Slider))

export default StyledSlider
