import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { BaseProps } from "../../utils/baseProps"
import { makeStyles, createStyles } from "@material-ui/styles"
import { Theme, darken } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: darken(theme.palette.primary.main, 0.3),
      opacity: 0.75,
      zIndex: -1,
    },
  })
)

const InformacjaBg: React.FC<BaseProps> = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "informacja.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const styles = useStyles()

  return (
    <>
      <Img
        alt="informacja_tło"
        className={className}
        fluid={data.placeholderImage.childImageSharp.fluid}
        imgStyle={{
          objectPosition: "center",
        }}
      />
      <div className={styles.overlay}></div>
    </>
  )
}

export default InformacjaBg
