import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles, Theme, createStyles } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: "53px",
      [theme.breakpoints.down("sm")]: {
        height: "43px",
        marginBottom: "30px",
      },
    },
  })
)

export const Logo = () => {
  const styles = useStyles()

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo.png" }) {
        publicURL
      }
    }
  `)

  return (
    <img
      alt="polenergia_logo"
      className={styles.logo}
      src={data.file.publicURL}
    />
  )
}
